import React, { Component } from "react";
import { apiFetch, saveSession  } from "../helpers/APIHelper";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import LandingBanner from "../components/LandingBanner";
import LandingSteps from "../components/LandingSteps";
import LandingAssortment from "../components/LandingAssortment";
import LandingVideo from "../components/LandingVideo";
import MainContent from "../components/MainContent";
import ShopTheCardsBanner from "../components/ShopTheCardsBanner";
import LandingCustomBanner from "../components/LandingCustomBanner";

import step1 from "../assets/images/step-1-digital-update.jpg";
import step2 from "../assets/images/step-2-invite.jpg";
import step3 from "../assets/images/step-3-digital.jpg";
import TrackingHelper from "../helpers/TrackingHelper";

const stepArray = [
  {
    image: step1,
    imageAlt: 'Step 1',
    imageHeader: 'Add photos, video and music',
    imageBody: 'Record your video message, and choose an intro and music from a selection.'
  },
  {
    image: step2,
    imageAlt: 'Step 2',
    imageHeader: 'Invite others to join in',
    imageBody: 'Friends and family can upload photos and videos, too. You choose which ones to use, and set the order.'
  },
  {
    image: step3,
    imageAlt: 'Step 3',
    imageHeader: 'Send instantly for them to enjoy',
    imageBody: 'We stitch it together for you to send via text or email. It’s a moment they’ll never expect—or forget.'
  }
];
export class LandingPage extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount(){
    document.title = 'Digital Video Greetings Online | Hallmark eCards Alternative';
    document.getElementsByTagName( 'meta' )[3].content = "Easily create a personalized video greeting alone or with friends. Add photos, video and music. Send instantly, like a Hallmark ecard—ready to save and share."
  }



  render() {
    return (
      <div>
        <NavBar />
        <MainContent>
          <LandingBanner /> 
          <LandingVideo />
          <LandingSteps stepArray={stepArray}/>
          <ShopTheCardsBanner /> 
          <LandingAssortment/>

        </MainContent>
        <Footer />
      </div>
    );
  }
}

export default LandingPage;
