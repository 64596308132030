import React, { Component } from "react";
import Footer from "../components/Footer";
import { apiFetch, saveSession, logToServer } from "../helpers/APIHelper";
import { getQRKey, getBaseURL, isBadQRReaderApp } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";

import LandingBanner from "../components/LandingBanner";
import LandingSteps from "../components/LandingSteps";
import LandingMailer from "../components/LandingMailer";
import LandingVideo from "../components/LandingVideo";
import StandardModal from "../modal/StandardModal";
import step1 from "../assets/images/step-1-physical.jpg";
import step2 from "../assets/images/step-2-invite.jpg";
import step3 from "../assets/images/step-3-physical.jpg";
import NavLogo from "../components/NavLogo";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import { Button, Modal } from "react-bootstrap";
import Plyr from "react-plyr";
import QuickSignup from "../components/QuickSignup";
import menuclose from "../assets/images/HMK-Icons/Icons_Menu_Close_Bold.svg";

const stepArray = [
  {
    image: step1,
    imageAlt: 'Step 1',
    imageHeader: 'Scan the code inside the card',
    imageBody: 'We’ll give easy, step-by-step instructions to create your video.'
  },
  {
    image: step2,
    imageAlt: 'Step 2',
    imageHeader: 'Add videos and invite others',
    imageBody: "Collect photos and videos in a snap, and choose music. When you’re done, we’ll stitch them together."
  },
  {
    image: step3,
    imageAlt: 'Step 3',
    imageHeader: 'Mail for them to enjoy',
    imageBody: "When they scan the code, they'll be able to watch their video, save it, and even share on social."
  }
];
export default class QRScan extends Component {


  constructor(props) {
    super(props);
    this.state = {
      imgTall: '',
      isSignedIn: false,
      hasOwner: false,
      isQRCardOwner: false,
      previewVideo: null,
      isLoading: true,
      showHowToVideo: false,
      userHasBoxCards: false,
      showQuickSignupModal: false,
    }
  }

  componentDidMount() {
    this._getData();



    logToServer( 
      'QRScan.load' +
      ' webView=' + ( isBadQRReaderApp() ? 'bad ' : 'good ' ) +
      ' innerHeight=' + window.innerHeight + 
      ' innerWidth=' + window.innerWidth + 
      ' screenHeight=' + window.screen.height + 
      ' screenWidth=' + window.screen.width + 
      ' localStorage=' + (!!localStorage)+ 
      ' sessionStorage=' + (!!sessionStorage)
    );


  }

  _getData = () => {


    apiFetch(`/rest/qr/${getQRKey()}?auto-account=true`, {
      method: 'GET',
    }, (json) => {
      console.log(json);

      saveSession(json)

      if( json.isQRCardOwner ){
        if( json.editFizzId ){
          window.location = '/edit/' + json.editFizzId;
          return;
        } else if( !json.isBoxSet ){
          window.location = getBaseURL('/create/' + json.createThemeAnimationId);
          return;
        }
      }

      this.setState({
          editFizzId: json.editFizzId,
          isLoading: false,
          imgTall: json.imgTall,
          isSignedIn: json.isSignedIn,
          isQRCardOwner: json.isQRCardOwner,
          hasOwner: json.hasOwner,
          previewVideo: json.previewVideo,
          userHasBoxCards: json.userHasBoxCards,
          isBoxSet: json.isBoxSet,
          createThemeAnimationId: json.createThemeAnimationId,
          userEmail: json.userEmail,
          howToVideo: json.howToVideo
      });
      document.title = 'Video Greeting Cards | Video Greetings | Hallmark';
      document.getElementsByTagName( 'meta' )[3].content = "Give them a unique video keepsake to watch again and again. Personalize a video greeting card with their name and music. Invite others to add video messages."
      
      TrackingHelper.trackPage();
    }, (error) => {
      this.setState({
        isLoading: false,
        error: error+""
      })

      document.title = 'Video Greeting Cards | Video Greetings | Hallmark';
      document.getElementsByTagName( 'meta' )[3].content = "Give them a unique video keepsake to watch again and again. Personalize a video greeting card with their name and music. Invite others to add video messages."
      TrackingHelper.trackPage();
    });
  }

  handleShowHowToVideo = () => {
    this.setState(prevState => ({
      ...prevState,
      showHowToVideo: true
    }));
  }

  handleCloseHowToVideo = () => {
    this.setState(prevState => ({
      ...prevState,
      showHowToVideo: false
    }));
  }

  handleShowQuickSignupModal = () => {
    this.setState(prevState => ({
      ...prevState,
      showQuickSignupModal: true
    }));
  }

  closeQuickSignupModal = () => {

    this.setState(prevState => ({
      ...prevState,
      showQuickSignupModal: false
    }));
  }

  onSignupSuccess = () => {

    this._getData();
    this.setState({
      showQuickSignupModal: false
    });
  }

  render() {
    if( this.state.isLoading ){
      return(
        <div>
          <NavLogo/>
          <MainContent className="container bg-margin text-center pt-5">
            <Spinner/>
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }

    return (
      <div className="screen-wrap" >
        <NavLogo />
        {this.state.isBoxSet ?
          <MainContent className="container mt-4 d-flex flex-column align-items-center">
            <h1 className="headline-text text-center">Let’s get started on your boxed set of cards!</h1>
            <div className="text-center my-3">Create a personalized Video Greeting. We’ll help you link it to all the cards in this boxed set, so everyone can see it.</div>
            <div className="QRBox-thumbnail-wrapper">
              <img src={ this.state.imgTall } style={{maxWidth: '100%', maxHeight: '100%'}}/>
            </div>
            <div className="d-flex flex-column align-items-center qr-box-cta-wrapper">
              { this.state.howToVideo && <Button variant="btn btn-outline-primary w-100" onClick={this.handleShowHowToVideo}>Watch a quick how-to</Button> }
             
              { ( this.state.hasOwner && !this.state.isQRCardOwner ) ? (
                  <button className="btn btn-primary w-100"  onClick={this.handleShowQuickSignupModal}>
                    SIGN IN TO CONTINUE
                  </button>
              ) : (
                  <Link 
                    className="btn btn-primary w-100" 
                    to={{
                      pathname: this.state.userHasBoxCards ? getBaseURL( `/${this.state.createThemeAnimationId}/add` ) : getBaseURL(`/create/${this.state.createThemeAnimationId}`),
                      state: {isBoxSet: true}
                    }}
                  >
                    Continue
                  </Link>        
              )}
              
            </div>

            <div className="d-flex flex-column align-items-center my-4">
              { this.state.userEmail ? ( 
                <small>You're signed in as: {this.state.userEmail}</small>
              ) : (
                <small>You are not signed in. Sign in if you want to link this to an existing box set.</small>
              )}

              <button className="btn btn-link pl-0 blue-link" onClick={this.handleShowQuickSignupModal}>
                <small>{ this.state.userEmail && 'Not you? ' }Sign in to your account.</small>
              </button>
            </div>
            <StandardModal
                size='lg'
                show={this.state.showHowToVideo}
                onHide={this.handleCloseHowToVideo}
                closeBtnText="Close"
            >
                
                  <Plyr
                    type="video" // or "vimeo"
                    url={this.state.howToVideo || 'https://static.hallmarkvideogreetingcards.com/pub/how-to-video-greeting-card-v1-30cq.mp4'}
                    autoplay
                    options={{playsinline:true}}
                  />

              </StandardModal>

              <Modal
                show={this.state.showQuickSignupModal}
                onHide={this.closeQuickSignupModal}
                size='lg'
              >
                <Modal.Header className="text-left pb-0">
                  <div onClick={this.closeQuickSignupModal}>
                    <span className="close">
                      <img src={menuclose} alt="menuclose" />
                    </span>
                  </div>
                </Modal.Header>
                <Modal.Body className="pt-0">
                  <QuickSignup successCallback={this.onSignupSuccess} preventRedirect = {true} from="QRScan"/>
                </Modal.Body>
              </Modal>
          </MainContent>
        :
          <MainContent className="container">
            <div className="row mt-5 mb-5">
              <div className="col-md-8 col-xs-12 d-md-block text-center">
                <h1 className="headline">Welcome!<br/>Let’s get started…</h1>
                {this.state.error ?
                  <p className="alert alert-danger">{this.state.error}</p>
                :(
                  <>
                    <h6 className="subheadline">
                      Thanks for buying a Hallmark Video Greeting Card. Let’s get started creating a one-of-a-kind moment for someone special.
                    </h6>
                    { ( this.state.hasOwner && !this.state.isQRCardOwner ) ? (

                      <Link to={getBaseURL("/login")} className="btn btn-primary mb-2">
                        SIGN IN TO CONTINUE
                      </Link>

                    ) : (

                      <Link to={getBaseURL('/create/' + this.state.createThemeAnimationId)} className="btn btn-primary mb-2">
                        START YOUR VIDEO GREETING
                      </Link>          

                    )}
                  </>
                )}
              </div>
              <div className="col-md-4 col-xs-12 d-md-block">
                <div className="step_container">
                  <img src={ this.state.imgTall } style={{maxWidth: '100%', maxHeight: '100%'}}/>
                </div>
              </div>
            </div>
          </MainContent>
        }

        { !this.state.isBoxSet && <LandingBanner isQRCard={true}/> }
        <LandingSteps stepArray={stepArray}/>
        <LandingVideo />
        {/* <LandingMailer previewVideo = {this.state.previewVideo} /> */} 
        {this.state.editFizzId && 
          <div class="video-segment pt-0">
            <p class="subheadline text-center mt-0">{this.state.editFizzId}</p>
          </div>
        }
        <Footer/>
      </div>
    );
  }
}
