import React, { Component } from 'react';
import { Button,Modal } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import Plyr from 'react-plyr';
import { Link } from 'react-router-dom';
import { apiFetch } from '../helpers/APIHelper';
import Spinner from '../components/Spinner';
import Notify from '../components/Notify';
import loginImg from "../assets/images/video-greetings-digital-sign-in.jpg";
import TrackingHelper from '../helpers/TrackingHelper';
import MainContent from '../components/MainContent';
import { getQRKey } from "../helpers/URLHelper";

export default class PasswordReset extends Component {
  
  constructor(props, context) {
    super(props, context);

    this.state = {
      isSending:false,
      email:""
    };
  }


  componentDidMount() {
    document.title = 'Password Reset | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    TrackingHelper.trackPage();
  }



  handleSubmit = (event)=>{
    event.preventDefault();
    this.setState({
      isSending:true
    })


    apiFetch( "/rest/user/send-password-link", {
        method: "POST",
        data: { email: this.state.email },
      },
      (json) => {
        this.setState({
          isSending: false,
          confirmation: "The email has been sent. Check your email for a link to change your password.",
        });

      },
      (error) => {
        this.setState({
          isSending: false,
          errorMessage: error + "",
        });
      }
    );


  }


  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  hideConfirm = ()=>{
    this.setState({
      confirmation:null
    })
  }

  render () {

    var allowSubmit = this.state.email.length > 0 && !this.state.isSending;

    return (
      <div className="screen-wrap" >
        { getQRKey() ? 
          <NavLogo />
          :
          <NavBar />
        }
        <MainContent className="container">

          <div className="row">
            <div className="col-12 col-md-6">
              <h1 className="headline-text">Forgot password?</h1>
              <h2 className="subheadline">We’ll send you an email to reset your password for your Hallmark Video Greetings account.</h2>


              { this.state.confirmation && 
                <Notify message={this.state.confirmation} onHide={this.hideConfirm} />
              }
              { !this.state.confirmation &&
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <span className="has-float-label">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        value={this.state.email}
                        onChange={this.handleChange}
                        id="email"
                        required={true}
                        // aria-describedby="email-help"
                        // placeholder="Enter Email"
                        // pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                      />
                    </span>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-block btn-lg-inline-block btn-primary mt-2"
                    disabled={!allowSubmit}
                  >
                    Send Reset Password Link
                  </button>

                  { this.state.isSending &&
                    <div>
                      <Spinner />
                    </div>
                  }

                  {this.state.errorMessage && (
                    <p className="alert alert-danger mt-2">
                      {this.state.errorMessage}
                    </p>
                  )}
                </form>
              }
            </div>

            <div className="col-md-6 text-center d-none d-md-block">
              <img className="w-100 mt-4" src={loginImg} alt="Make Moments Last A Lifetime"/>
            </div>
          </div>


        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }
}