import React, { Component } from "react";
import ReactDOMServer from 'react-dom/server';
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import { apiFetch, isLoggedIn } from "../helpers/APIHelper";
import FindACard from "../components/FindACard";
import StandardModal from "../modal/StandardModal";
import Plyr from "react-plyr";

import Constants from "../helpers/Constants";
import BannerBar from "../components/BannerBar";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import Spinner from "../components/Spinner";
import TrialAccountBanner from "../components/TrialAccountBanner";
import { Link } from "react-router-dom";
import ThemeIcons from '../assets/images/invite-carousel-solid.png';

export default class ViewInviteThankYou extends Component {


  constructor(props) {
    super(props);
    this.state = {
      popVideo: '',
      popOffer:true,
    }
  }

  componentDidMount(){

    this.getData();

    document.title = 'Thank you for Contributing | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "All set. Your photo or video has made its way to the group surprise—thanks for being part of a very special moment.";
    TrackingHelper.trackPage();
  }

  getData = () => {
    this.setState({
      isLoading: true,
    });
    apiFetch(
      "/rest/user/actor-info",
      {
        method: "GET",
      },
      (json) => {

        if( json.offer ){
          this.setState( {
            offer: json.offer
          })

        }

        // if ( json.redirect == "login" ){
        //   window.location = "/view-invite-trial-login";
        //   return; 
        // } else if ( json.redirect == "contact") {
        //   window.location = "/view-invite-contact";
        //   return;
        // } else if ( json.redirect == "register") {
        //   window.location = "/view-invite-trial-register";
        //   return;
        // }

        this.setState({
          isLoading: false
        });

      },
      (error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          loadingError: error.message + ""
        })
      }
    );
  };

  sendOfferSkip = () => {

    console.log("sendOfferSkip");

    apiFetch(
      "/rest/user/actor-info?result=skip",
      {
        method: "GET",
      },
      (json) => {
        // fire and forget
      },
      (error) => {
        // fire and forget
      }
    );
  };

  openHowToVideo=()=>{
    this.setState({
      popVideo:"https://static.hallmarkvideogreetingcards.com/pub/how-to-video-greeting-card-v1-30cq.mp4",
    })
  }

  renderOfferPage = () => {


    var top = "";
    var bottom = "";
    if( this.state.offer && this.state.offer.ui ) {

      var scroll = ReactDOMServer.renderToStaticMarkup(
        <div class="marquee">
          <div class="marquee__inner">
            <img src={ThemeIcons} />
            <img src={ThemeIcons} />
            <img src={ThemeIcons} />
            <img src={ThemeIcons} />
          </div>
        </div>   
      );

      if( this.state.offer.ui.top ) {
        top = this.state.offer.ui.top.replace('SCROLLING_ICONS',scroll)
      }

      // top = ReactDOMServer.renderToStaticMarkup(
      //   <div>
      //     <h2 className="headline-offer mb-4">Your upload was successful.</h2> 
      //     <h2 className="headline-text mb-4">Know someone celebrating soon or are you still looking for something unique for Mother's Day?</h2> 
      //     <h2 className="headline-offer mb-0"> </h2> 

      //     <div> 
      //       <h2 className="headline-text mb-2">You earned a <b>FREE</b> Video Greeting for participating in this video!</h2> 
      //     </div> 

      //     <ul style={{paddingLeft:'12px'}}> 
      //       <li>Choose any occasion, or "just because"</li> 
      //       <li>Start your free greeting within 30 days</li> 
      //       <li>Digital delivery only (via text or email)</li>
      //     </ul>
      //   </div>
      // );



      if( this.state.offer.ui.bottom ) {
        bottom = this.state.offer.ui.bottom
      }

      // bottom = ReactDOMServer.renderToStaticMarkup(
      //   <div> 
      //     SCROLLING_ICONS
      //   </div>
      // );

      // console.log('t', JSON.stringify(top) );
      // console.log('b', JSON.stringify(bottom) );

      bottom = bottom.replace('SCROLLING_ICONS',scroll)

    }
    return (

                this.state.offer.ui && (top || bottom) ?
                  <>
                    {top &&
                      <div dangerouslySetInnerHTML={{__html:top}} />
                    }
                    <div className="mt-0 mb-2">
                      <Link
                        to="/view-invite-trial-register"
                        className="btn btn-primary btn-block"
                      >
                        { this.state.offer.ui.claimBtn || "Claim Your Free Video Greeting" }
                      </Link>
                    </div>

                    {bottom &&
                      <div dangerouslySetInnerHTML={{__html:bottom}} />
                    }
                  </>
                  :
                  <>
                    <h2 class="headline-offer mb-4">Your upload was successful.</h2>


                    <h2 className="headline-offer mb-0">You made someone's day and earned a <br/><strong>FREE HALLMARK<br/>VIDEO GREETING!</strong></h2>


                    <div className="mt-0 mb-2">
                      <Link
                        to="/view-invite-trial-register"
                        className="btn btn-primary btn-block"
                      >
                        Claim Your Free Video Greeting
                      </Link>
                    </div>
                    <ul style={{paddingLeft:'12px'}} >
                      <li>Choose any occasion, or "just because"</li>
                      <li>Start your free greeting within {this.state.offer.expiresInDays} days</li>
                      <li>Digital delivery only (via text or email)</li>
                    </ul>  


                    <div class="marquee">
                      <div class="marquee__inner">
                        <img src={ThemeIcons} />
                        <img src={ThemeIcons} />
                        <img src={ThemeIcons} />
                        <img src={ThemeIcons} />
                      </div>
                    </div>
                  </>
                
          );
  }

  render() {

    if( this.state.isLoading ){
      return(
        <div className="screen-wrap">
          <NavLogo/>
          <BannerBar />
          <MainContent className="container bg-margin">
            <Spinner float={true}/>
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }

    var hasOffer = !!this.state.offer;
    var hasOfferPopup = this.state.offer && (this.state.offer.ui||{}).isPopup;

    return (
      <div className="screen-wrap" >
        <NavLogo />
        <BannerBar />
        <MainContent className="container pb-5 pt-3">


          { hasOffer && !hasOfferPopup ? (

            <div className="row" >
              <div className="col-md-8 offset-md-2">
                {this.renderOfferPage()}
              </div>
            </div>           

          ) : (
            
            <>

              { hasOffer && hasOfferPopup &&

                <StandardModal
                  size='lg'
                  show={!!this.state.popOffer}
                  onHide={()=>{ this.setState({popOffer:false});this.sendOfferSkip() }}
                  closeBtnText={false} >

                  {this.renderOfferPage()}
                  <div className="text-center mt-3" >
                    <button className="btn btn-link blue-link" onClick={()=>{ this.setState({popOffer:false});this.sendOfferSkip() }}  >skip</button>
                  </div>

                </StandardModal>
              }
              <h1 className="headline-text">Success!</h1>
              <h5 className="subheadline">All set. Your photo or video has made its way to the group surprise—thanks for being part of a very special moment.</h5>


              <div className="row" >
                <div className="col-md-6 mb-4" >

                  { hasOffer && hasOfferPopup ?
                    ( 
                      (  (this.state.offer.ui||{}).behindPopup ?
                        <div dangerouslySetInnerHTML={{__html: this.state.offer.ui.behindPopup }} />
                        :
                        <div>
                            <h2 className="headline-22">Want to create your own Video Greeting?</h2>
                            <h5 className="subheadline">You can send one today for FREE, or you can mail your Video Greeting inside a real Hallmark card.</h5>
                            <div>
                              <button className="btn btn-primary btn-block btn-lg-inline-block mr-4" onClick={()=>{ this.setState({popOffer:true});}} >Create a Digital Greeting</button>
                              
                              <a className="btn btn-outline-primary  btn-block btn-lg-inline-block" href={Constants.HALLMARK_LANDING_FROM_INVITE}>shop the cards</a>
                            </div>
                          </div>
                      )
                    )
                    :
                    <FindACard isInvite={true} />
                  }
                </div>
                <div className="col-md-6" >
                  <h2 className="headline-text mb-2">
                    See how it works
                  </h2>
                  <div className="video-thumb w-100" onClick={this.openHowToVideo} >
                    <img className="w-100" 
                      src="https://static.hallmarkvideogreetingcards.com/pub/how-to-video-greeting-card-v1.jpg" />
                  </div>
                </div>
              </div>
            </>
          )}

        </MainContent>

        <StandardModal
          size='lg'
          show={!!this.state.popVideo}
          onHide={()=>{ this.setState({popVideo:null}) }}
          closeBtnText={false} >
            <Plyr
              type="video" // or "vimeo"
              url={this.state.popVideo}
              options={{playsinline:true}}
              autoplay
              
            />
        </StandardModal>

        <div className="simple-footer">
          <Footer hideMobile={false} />
        </div>
      </div>
    );
  }
}
