import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { apiFetch } from '../helpers/APIHelper';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';
import SpecialFieldEditor from '../components/SpecialFieldEditor';
import FizzLogoOptions from '../components/FizzLogoOptions';
import Spinner from '../components/Spinner';
import StandardModal from '../modal/StandardModal';


export default class MusicModal extends Component {

	constructor(props) {
		super(props);

		this.state = {
			show: true
		}
		this.loadProps(props);
	}

	componentWillMount() {
	}
	componentWillReceiveProps(nextProps){

		var old = this.state.selectedMusic;
		this.loadProps(nextProps);
		if( old != this.state.selectedMusic ) {
			if( this.props.onSelect ) {
				this.props.onSelect(this.state.selectedMusic);
			}
		}
	}
	loadProps( props ) {
		this.state.show = props.show;
		this.state.selectedMusic = this.getSelectedMusic(props.music);
		this.state.pickedMusic = this.state.selectedMusic;

		if( props.show && !this.isShowing ) {
			this.isShowing = true;
			setTimeout(()=>{
				let el = this.playAudio(this.state.selectedMusic);
				if( el && el.parentElement && el.parentElement.scrollIntoView ){
					el.parentElement.scrollIntoView()
				}
			},10);
		}
	}


	close = () => {
		this.isShowing = false;
		this.setState({
			show:false
		})
		if( this.props.onClose ) {
			this.props.onClose();
		}
	}
	save = ( ) => {
		this.isShowing = false;
		if( this.props.onSave ) {
			this.props.onSave(this.state.pickedMusic);
		}
	}

	playAudio = (music) => { 
		// Stop the old
		if(this.state.pickedMusic != music && this.state.pickedMusic != null) {
			var y = document.getElementById('music-'+this.state.pickedMusic.id);
			if( y ) {
				y.pause();
			}
		}
		this.setState({
			pickedMusic: music
		});

		// Play new
		var x = document.getElementById('music-'+music.id);
		if( x ) {
			x.play();
			return x;
		}
	}


	getSelectedMusic = ( musicPickerObj ) => {
		if( musicPickerObj && musicPickerObj.options ) {
			for (var i = 0; i < musicPickerObj.options.length; i++) {
				let music = musicPickerObj.options[i];
				if( music.id == musicPickerObj.defaultMusicId ||  music.id == musicPickerObj.defaultId ) {
					return music;
				}
			}
		}
		return null;
	}


	render() {

		var styles = {
			musicModalScroll: {
				maxHeight: '400px',
				overflowY: 'scroll'
			}
		};

		if( this.props.music && this.props.music.options && this.props.music.options.length>1 ) {

			return (
				<Modal show={this.state.show} onHide={this.close} size='lg' >
					<Modal.Body style={styles.musicModalScroll}>
						<ul className="list-group music-picker-list">

							{this.props.music.options.map((music) =>{
								if( music.id==-2) {
									return null;
								}
								return (
									<li key={music.id} className="list-group-item" onClick={this.playAudio.bind(this, music)} >
										<audio id={'music-'+music.id} preload="none" >
										  <source src={music.url} type="audio/mpeg" />
										</audio>	
										{this.state.pickedMusic == music && 
											<span className="icon-checkmark"></span>
										}
										&nbsp; <strong>{music.name}</strong>
									</li>
								);
							})}   
						</ul>
					</Modal.Body>
					<Modal.Footer>
						{this.props.status &&
							<span>{this.props.status}</span>
						}
						<Button variant="primary  btn-block" onClick={this.save}  >
							Done
						</Button>
						<Button variant="outline-primary btn-block" onClick={this.close} >
							Cancel
						</Button>
					</Modal.Footer>
				</Modal>

				);
		}
		return null;

	}
}