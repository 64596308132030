import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../assets/css/LandingPage.css";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselItem from "./CarouselItem";

const responsive = {
  md_lg_xl: {
    breakpoint: { max:99999, min: 768 },
    items: 3,
  },
  sm: {
    // breakpoint: { max: 576, min: 0 },
    // items: 1,
    breakpoint: { max: 768, min: 576 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 120 // this is needed to tell the amount of px that should be visible.
  },
  xs:{
    breakpoint: { max: 576, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 60 // this is needed to tell the amount of px that should be visible.
  }
};


export class LandingSteps extends Component {
  render() {
    return (
        <>
          <div className="mt-5 container text-center">
            <h5 className="landing_title mt-4">Create a Digital Video Greeting in&nbsp;3&nbsp;easy&nbsp;steps</h5>
          </div>
          <section className="mb-3 step_loader container-md p-0">
            <Carousel 
              responsive={responsive}
              showDots={true}
              partialVisible ={true}
              removeArrowOnDeviceType={["xs","sm","md_lg_xl"]}
              dotListClass="custom-dot-list-style"
              
            >
             { this.props.stepArray.map( item => 
                <CarouselItem
                  image={item.image}
                  imageAlt={item.imageAlt}
                  imageHeader={item.imageHeader}
                  imageBody={item.imageBody}
                />
              )}
            </Carousel>
          </section>
        </>
    );
  }
}

export default LandingSteps;
