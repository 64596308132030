import React, { Component } from "react";
import ReactDOMServer from 'react-dom/server';
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import NavLogo from "../components/NavLogo";
import BannerBar from "../components/BannerBar";
import Footer from "../components/Footer";
import "../assets/css/SignIn.css";
import { apiFetch, generateDeviceId, isLoggedIn, saveSession } from "../helpers/APIHelper";
import { getBaseURL, getQRKey } from "../helpers/URLHelper";
import loginImgPhysical from "../assets/images/account-side.jpg";
import loginImg from "../assets/images/video-greetings-digital-sign-in.jpg";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import Notify from "../components/Notify";
import { toastManager } from "../components/Toaster";
import TrialAccountBanner from "../components/TrialAccountBanner";
import Spinner from "../components/Spinner";
import ThemeIcons from '../assets/images/invite-carousel-solid.png';
class Login extends Component {


  constructor(props) {
    super(props);


    var redirect = null;
    var redirectEncoded = "";
    if ( props.match ){
      redirectEncoded = props.match.params.redirect || "";
      redirect = decodeURIComponent( redirectEncoded || "");
    }

    this.state = {
      forgetPassword: null,
      email: "",
      password: "",
      openWelcome: false,
      viewTutorial: null,
      redirect: redirect,
      redirectEncoded: redirectEncoded,
      isQRCard: false
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };
  
  componentDidMount() {

    document.title = 'Sign in | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    if( this.props.blockRedirectQR ){
      this.setState( { isQRCard: true } );
    } else if ( this.props.isTrialAccount ){

      this.setState({ 
        isTrialAccount: true,
      });
      this.getOfferUserData();

    } else if ( this.props.isShareOffer ){

      this.setState({ 
        isShareOffer: true,
      });
      this.getOfferUserData();

    } else { 
      isLoggedIn() && this.props.history.goBack();
    }
    TrackingHelper.trackPage();
  }

  getOfferUserData = () => {

    this.setState({
      loading: true,
    });

    apiFetch(
      this.props.isTrialAccount ? "/rest/user/actor-info" : "rest/offer/info?page=login",
      {
        method: "GET",
      },
      (json) => {

        this.setState({
          loading: false,
          offer: json.offer,
          email: json.email,
          // themeImg: json.themeImg
        });
      },
      (error) => {
        console.log(error);
        this.setState({
          loading: false,
          error: error.message + ""
        })
      }
    );
  };

  validateForm() {
    return this.state.email && this.state.email.length > 0 && this.state.password && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ loginErrorMessage: null });

    var from = null;

    if( this.state.isTrialAccount ){
      from = 'actor';
    } else if ( this.state.isShareOffer ){
      from = 'recipient';
    }

    var data = {
      qrKey: getQRKey(),
      version: "999",
      offer: this.state.offer,
      from: from,
      user: {
        deviceType: 1, // 3 == web
        email: this.state.email,
        password: this.state.password,
        deviceId: generateDeviceId(),
      },
    };
    apiFetch(
      "/rest/user/login?type=site",
      {
        method: "POST",
        data: data,
      },
      (json) => {
        saveSession(json);
        
        if( this.state.isTrialAccount || this.state.isShareOffer ){

          switch(json.offerResult ){
            case 'invalid':
              toastManager.showToast({
                message: 'This offer is no longer valid.',
                error: 'error'
              });
              break;
            case 'duplicate':
              toastManager.showToast({
                message: 'You have already claimed this offer.',
                error: 'error'
              });
              break;
          }
        }
        
        
        if( json.editFizzId ) {
          this.props.history.push( '/edit/' + json.editFizzId )
        } else if ( json.createTaId ){
          this.props.history.push( getBaseURL('/create/' + json.createTaId) )
        } else if( this.state.redirect ) {
          this.props.history.push( this.state.redirect )
        } else if ( this.state.isTrialAccount || this.state.isShareOffer ){
          this.props.history.push( '/browse' )
        } else if( json.hasFizzes ) {
          this.props.history.push( '/list' )
        } else {
          this.props.history.push( '/' )
        }
      },
      (error) => {
        console.log("Error::", error);
        this.setState({ loginErrorMessage: error.message });
      }
    );
  };

  render() {
    var styles = {
      welcomeBg: {
        backgroundColor: "#eeeeee",
      },
    };


    return (
      <div className="screen-wrap">

        { ( getQRKey() || this.state.isTrialAccount ) ? 
          <NavLogo />
          :
          <NavBar />
        }

        { this.state.isTrialAccount  &&
          <BannerBar />
        }
        <MainContent className="container bg-margin">

          { this.state.loading ?
            <Spinner float={true} />
            :
            this.renderContent()
          }

        </MainContent>
        <Footer />
      </div>
    );
  }

  renderContent() {


    var scroll = ReactDOMServer.renderToStaticMarkup(
      <div class="marquee">
        <div class="marquee__inner">
          <img src={ThemeIcons} />
          <img src={ThemeIcons} />
          <img src={ThemeIcons} />
          <img src={ThemeIcons} />
        </div>
      </div>   
    );

    var claimMarkup = null;
    if( this.state.offer &&  this.state.offer.ui && this.state.offer.ui.claimMarkup ) {
      claimMarkup = this.state.offer.ui.claimMarkup.replace('SCROLLING_ICONS',scroll)
    }

    
    return (
      <div className="row">
        <div className="col-md-6">
        
          { this.state.offer ? (
            <>  
              { claimMarkup ? (
                  <div dangerouslySetInnerHTML={{__html:claimMarkup}} />
              ) : (
                  <h1 className="headline-text">{ (this.state.offer.ui||{}).signInText || "Sign in so your offer is saved:"}</h1>
              )}
            </>
          ) : (
            <h1 className="headline-text">Sign in</h1>
          )}

          <form className="sign-form mt-4" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <span className="has-float-label">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={this.state.email}
                  onChange={this.handleChange}
                  id="email"
                  // aria-describedby="email-help"
                  // placeholder="Enter Email"
                  // pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                  required
                />
              </span>
            </div>

            <div className="form-group">
              <span className="has-float-label">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={this.state.password}
                  onChange={this.handleChange}
                  id="password"
                  // placeholder="Enter Password"
                  required
                />
              </span>
            </div>
            <button
              type="submit"
              className="text-uppercase btn btn-block btn-primary mt-4 mb-3"
              disabled={ this.validateForm }
            >
              SIGN IN
            </button>

            {this.state.loginErrorMessage && (
              <Notify 
                float={false}
                message={this.state.loginErrorMessage}
                error={true}
              />
            )}

          </form>

          <div className="subheadline mt-3">
            <Link className="blue-link" to={getBaseURL("/password-reset")}>
              Forgot password?
            </Link>
          </div>

          <div className="subheadline mt-4 pt-4">
            <div className="mb-1" >New to Hallmark Video Greetings?</div>
              { ( this.state.isTrialAccount || this.state.isShareOffer ) ? (

                <Link className="blue-link" to={ this.state.isTrialAccount ? "/view-invite-trial-register" : "/offer-register" }>
                  Create an account
                </Link>

              ) : (

                <Link className="blue-link" to={this.state.redirectEncoded ? getBaseURL('/register/'+this.state.redirectEncoded) : getBaseURL("/register") }>
                  Create an account
                </Link>
              )}
          
          </div>

        </div>

        <div className="col-md-6 text-center d-none d-md-block">
          <img className="w-100 mt-4" src={loginImg} alt="Make Moments Last A Lifetime"/>
        </div>

      </div>
    );
  }
}

export default Login;
