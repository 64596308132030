import React, { Component } from "react";
import { apiFetch, saveSession } from "../helpers/APIHelper";
import NavBar from "../components/NavBar";
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import SpecialFieldEditor from "../components/SpecialFieldEditor";
import FizzLogoOptions from "../components/FizzLogoOptions";
import Spinner from "../components/Spinner";
import MusicModal from "../modal/MusicModal";
import StandardModal from "../modal/StandardModal";
import { getQRKey, getBaseURL } from "../helpers/URLHelper";
import DownIcon from "../assets/images/HMK-Icons/Icons_Arrows_Norgie_Down_Bold.svg"
import BackLink from "../components/BackLink";
import CaretLeftIcon from "../assets/images/HMK-Icons/Icons_Arrows_Caret_Left_Bold.svg";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import Speaker from "../assets/images/HMK-Icons/VolumeHigh.svg";
import HolidayBoxSetStack from "../assets/images/holiday-box-set-2022.jpg";


export default class CreateFizz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBoxSetAlert: false, 
      isLoading: true,
      showMusicPicker: false,
      music: [],
      apiimage: "",
      specialFields: [],
      imageString: "%0A",
      themeId:null, // Set from API
      selectedLogo: null, // Set in callback from FizzLogoOptions _selectedLogoChange
      contactFields: [], // Set in callback from FizzLogoOptions _selectedLogoChange
      hasLogoContacts: false, // Set in getData + Set in callback from FizzLogoOptions _selectedLogoChange
      isNameLengthGreaterThanOne: false,
      unlockedPhysicalCards:[],
      displayErr: null,
    };
    this.loadProps(props);
  }


  hideConfirm = ()=>{
    this.setState({
      displayErr:null,
      createError:null
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.loadProps(nextProps)) {
      this._getData();
    }
  }
  loadProps(props) {
    var oldTA = this.state.taId;
    var oldFizzId = this.state.fizzId;
    const params = new URLSearchParams(props.location.search);
    this.state.taId = this.props.match.params.themeAnimationId;
    this.state.fizzId = this.props.match.params.fizzId;
    this.state.qrKey = this.props.match.params.qrKey;
    this.state.pt = params.get("pt");
    this.state.accessKey = params.get('key');
    return oldTA !== this.state.taId || oldFizzId !== this.state.fizzId;
  }

  componentWillMount() {
    this._getData();
    console.log(this.props);
    document.title = 'Personalize | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
  }

  _getData = () => {
    this.setState({
      displayErr: null,
      isLoading: true,
    });
    apiFetch(
      this.state.fizzId ? ("/rest/fizz/"+this.state.fizzId+"/edit") : ("/rest/theme-animation/"+this.state.taId+"?key="+this.state.accessKey),      
      {
        method: this.state.fizzId ? "GET" : "POST",
        data: this.state.fizzId ? undefined : {
          pt: this.state.pt,
          qrKey: this.state.qrKey,
        },
      },
      (json) => {
        saveSession(json);

        let jsonItem = json.item || json;
        this.setState(
          {
            isLoading: false,

            themeId: jsonItem.themeId,
            taId: this.state.taId || jsonItem.taId || jsonItem.themeAnimationId,
            previewImageCount: jsonItem.previewImageCount || 0,
            music: jsonItem.music,

            logo: jsonItem.logo,
            hasLogoContacts: jsonItem.hasLogoContacts,

            specialFields: jsonItem.special_field || jsonItem.specialFields || [],
            specialFieldsToSend: this.getSpecialFields(
              jsonItem.special_field || jsonItem.specialFields || []
            ),
            unlockedPhysicalCards: jsonItem.unlockedPhysicalCards || [],
            showBoxSetAlert: !!jsonItem.showBoxSetAlert,
          },
          () => {
            var selectedMusic = null;
            if (this.state.music) {
              for (var i = 0; i < this.state.music.options.length; i++) {
                var track = this.state.music.options[i];
                if (track.id == this.state.music.defaultMusicId) {
                  selectedMusic = track;
                  break;
                }
              }
            }

            var showExistingProjectModal = false
            if( !getQRKey() && this.state.unlockedPhysicalCards && this.state.unlockedPhysicalCards.length > 0) {
              showExistingProjectModal = true;
            }

            this.setState({
              selectedMusic: selectedMusic,
              imageString: this.buildSpecialFieldImageString(),
              showExistingProjectModal: showExistingProjectModal,
            });
          }
        );

        TrackingHelper.trackPage();
      },
      (error) => {
        this.setState({
          isLoading:false,
          displayErr:error.message
        });
        console.log("error message" + error.message);

        TrackingHelper.trackPage();
      }
    );
  };

  showMusicModal = () => {
    this.setState({
      showMusicPicker: true,
    });
  };
  onSaveMusic = (picked) => {
    if (picked) {
      this.state.music.defaultMusicId = picked.id;
    }
    this.setState({
      showMusicPicker: false,
      selectedMusic: picked,
    });
  };
  onCloseMusic = () => {
    this.setState({
      showMusicPicker: false,
    });
  };

  onCloseExistingProjectModal = () => {
    this.setState({
      showExistingProjectModal: false,
    })
  }
  onConfirmExistingProjectModal = () => {
    if (this.state.unlockedPhysicalCards.length == 1) {
      window.location.href = "/edit/" + this.state.unlockedPhysicalCards[0]
    }
    else {
      window.location.href = "/list"
    }
  }

  createFizz = () => {
    var fields = this.state.specialFieldsToSend;
    if (
      this.state.selectedLogo &&
      this.state.hasLogoContacts &&
      this.state.contactFields
    ) {
      fields = fields.concat(this.getSpecialFields(this.state.contactFields));
    }

    var areAllValid = true;
    for( var i=0; i<fields.length; i++ ){

      var field = fields[i];
      if( field.required==1 ){
        field.invalid = field.value ? (field.value.length===0) : true;
      }
      if( fields[i].invalid ){
        areAllValid = false;
      }

    }

    if( !areAllValid ) {
      this.setState({
        createError: "Please complete all required fields.",
      });
      return;
    }

    this.setState({
      createLoading: true,
      createError: null,
    });
    apiFetch(
      this.state.fizzId ? ("/rest/fizz/"+this.state.fizzId) : "/rest/fizz",
      {
        method: this.state.fizzId ?  "PUT" : "POST",
        data: {
          music: this.state.selectedMusic ? this.state.selectedMusic.id : null,
          specialFields: fields,
          logoTheme: this.state.selectedLogo
            ? this.state.selectedLogo.id
            : null,
          themeAnimation: this.state.taId,
          pt: this.state.pt,
          qrKey: getQRKey(),
          key: this.state.accessKey
        },
      },
      (json) => {
        this.setState({
          createLoading: false,
          createError: null,
        });
          window.location = '/edit/' + json.id;
      },
      (error) => {
        this.setState({
          createLoading: false,
          createError: error + "",
        });
        console.log(error.message);
      }
    );
  };

  getSpecialFields(groups) {
    var fields = [];
    for (var i = 0; i < groups.length; i++) {
      var group = groups[i];
      fields = fields.concat(group.items);
    }
    return fields;
  }

  onSpecialFieldsChange = (groups) => {
    this.setState({
      specialFieldsToSend: this.getSpecialFields(groups),
      createError: "", // Clear the error as they type
    });
    clearTimeout(this.textTimeout);
    this.textTimeout = setTimeout(() => {
      this.setState({
        imageString: this.buildSpecialFieldImageString(),
      });
    }, 500);
  };

  buildSpecialFieldImageString = () => {
    let imageString = "";
    if (this.state.specialFieldsToSend) {
      this.state.specialFieldsToSend.map((item) => {
        imageString += `${item.value}\n`;
      });
    }
    return encodeURIComponent(imageString);
  };

  selectedLogoChange = (obj) => {
    this.setState({
      selectedLogo: obj.selectedLogo,
      contactFields: obj.contactFields,
      hasLogoContacts: obj.hasLogoContacts,
    });
  };

 

  render() {
    var styles = {
      inputBox: {
        width: "100%",
        backgroundColor: "#efefef",
        color: "#343a40",
        borderRadius: "8px",
        border: "none",
        padding: "4px",
        paddingLeft: "8px",
        marginBottom: "15px",
      },
      changeMusicBox: {
        width: "100%",
        height:"auto",
        fontSize: "14px",
      },
      musicModalScroll: {
        maxHeight: "400px",
        overflowY: "scroll",
      },
    };

    var previewImages = [];
    for (var i = 0; i < this.state.previewImageCount; i++) {
      previewImages.push(
        "/rest/theme-animation/" +
          this.state.taId +
          "/image-preview/" +
          i +
          "/" +
          this.state.imageString
      );
    }

    var preivewMarkup = ( 
      <div>
        {previewImages.map((url) => (
          <img
            key={url}
            style={{ width: "100%", marginBottom: "10px" }}
            src={url}
          />
        ))}
      </div>
    );


    if( this.state.isLoading ){
      return(
        <div className="screen-wrap">       
          { getQRKey() ? 
            <NavLogo />
            :
            <NavBar />
          }
          <MainContent className="container bg-margin">
            <Spinner float={true} />
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }

    if( this.state.displayErr ){

      return(
        <div className="screen-wrap">
          <NavBar/>
          <MainContent className="container bg-margin">
            <p className="alert alert-danger mt-4">
              {this.state.displayErr}
            </p>
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }

    return (
      <div className="screen-wrap">

        { getQRKey() ? 
          <NavLogo />
          :
          <NavBar />
        }
        <MainContent className="container bg-margin">

          { !getQRKey() && 
            <p className="back_btn">
              { this.state.fizzId ?
                <BackLink
                  to={"/edit/"+this.state.fizzId }
                  title="Back to Edit"
                />
                :
                <BackLink
                  title="Back"
                />

              }
            </p>
          }

          <h1 className="headline-text">
            Personalize your Video {
              (this.props.location.state
              && !this.props.location.state.isBoxSet)
              && "Greeting"
            }
          </h1>

          {this.props.location.state && this.props.location.state.isBoxSet &&
            <p className="my-2">
              The Video Greeting you create will be linked to every card in your boxed set.
            </p>
          }

          <div className="row mt-3">
            <div className="col-md-6" >
              <SpecialFieldEditor
                  specialFields={this.state.specialFields}
                  onSpecialFieldsChange={this.onSpecialFieldsChange.bind(
                    this
                  )}
                />
              <FizzLogoOptions
                  hasLogoContacts={this.state.hasLogoContacts}
                  availableLogos={this.state.logo}
                  onSelectedLogoChange={this.selectedLogoChange}
                />

              {/* mobile web view show image between edits and music dropdown */}
              <div className="d-md-none"> 
                  {preivewMarkup}
              </div>

                  
              <div className="change_music">
                {this.state.music && (
                  <div className="form-group" >
                    <span className="has-float-label">
                      <label>Background music</label>
                      <button
                        className="form-control text-left"
                        style={{color:'#48464f'}}
                        onClick={this.showMusicModal}
                      >
                          {this.state.selectedMusic
                            ? this.state.selectedMusic.name
                            : "No Music"}
                          <img src={DownIcon} className="float-right icon-down"/>
                      </button>
                    </span>
                  </div>
                )}
              </div>

              <button
                tabIndex="0"
                onClick={this.createFizz}
                disabled={this.state.createLoading}
                className="btn btn-block btn-lg-inline-block btn-primary"
              >
                {this.state.fizzId ? 'Done' : 'Continue' }
              </button>

              <p className="subheadline mt-4"><i>*Required</i></p>

              {this.state.createLoading && <Spinner float={true} />}

              {this.state.createError  && (
                <p className="alert alert-danger mt-2">
                  {this.state.createError }
                </p>
              )}

            </div>

            <div className="col-md-6 d-none d-md-block" >
                {preivewMarkup}
            </div>
          </div>

          {
            this.props.location.state && !this.props.location.state.isBoxSet &&
            <div className="row">
              <div className="col-md-6 card-orange pb-3">
                <h2 className="headline-text">Quick Tips</h2>
                <ul className="card-body mb-1 pb-1">
                  <li>Horizontal (landscape) photos and videos work best.</li>
                  <li>On the next screen, click 'Add Photo or Video' to get started.</li>
                  <li>Invite contributors with the click of a button, then collect and add as many photos and videos as you want.</li>
                  <li>Look for the speaker icon to edit sound options.<i className="icon-mask mask-gray ml-2" style={{ WebkitMaskImage:"url("+Speaker+")"}} alt="Speaker Icon"></i></li>
                  <li>When you love your project, click "It's Finished".</li>
                </ul>
                <a href={getBaseURL("/faq")} className="btn btn-block btn-lg-inline-block btn-primary">SEE FAQ GUIDE</a>
              </div>
            </div>
          }
          
          <MusicModal
            show={this.state.showMusicPicker}
            music={this.state.music}
            onSave={this.onSaveMusic}
            onClose={this.onCloseMusic}
          />

          <StandardModal
            size="md"
            show={this.state.showExistingProjectModal}
            onHide={this.onCloseExistingProjectModal}
            header="You already started a Video Greeting"
            closeBtnText="Start a new video"
            confirmBtnText={this.state.unlockedPhysicalCards.length == 1 ? "EXISTING PROJECT" : "VIEW YOUR PROJECTS"}
            onConfirm={this.onConfirmExistingProjectModal}
          >
            Looks like you've already scanned your card and started a Video Greeting. Do you want to keep working on that existing project, or start a new, digital-only video?
          </StandardModal>


          {/*<StandardModal
            size="md"
            show={this.state.showBoxSetAlert}
            onHide={ ()=>{this.setState({showBoxSetAlert:false}) } }
            header="Need to send cards to more than 10 people?"
            confirmBtnText="GOT IT"
            closeBtnText={false}
            onConfirm={ ()=>{this.setState({showBoxSetAlert:false}) } }
          >
            <p style={{marginTop:'-38px',marginBottom:'30px'}}>
            Don’t worry, you can link this video to multiple boxed sets.
            </p>
              <img src={HolidayBoxSetStack} width="100%" />
          </StandardModal>*/}

          <StandardModal
            size="md"
            show={this.state.showBoxSetAlert}
            onHide={ ()=>{this.setState({showBoxSetAlert:false}) } }
            header={<span style={{fontSize:'22px'}}>You purchased a boxed&nbsp;set!</span>}
            confirmBtnText="Get Started"
            closeBtnText={false}
            onConfirm={ ()=>{this.setState({showBoxSetAlert:false}) } }
          >
            <p style={{fontSize:'20px'}} >
             This means every card in the box will have the same Video Greeting.
            </p>
              {/*<img src={HolidayBoxSetStack} width="100%" />*/}
          </StandardModal>



        </MainContent>

        <Footer hideMobile={true} />
      </div>
    );
  }
}
