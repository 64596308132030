import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import terms from "../assets/images/faq/terms.jpg"
import list from "../assets/images/faq/S1.png"
import pencilIcon from "../assets/images/faq/pencilIcon.svg"
import moveIcon from "../assets/images/faq/moveIcon.svg"
import chromeSettingsIcon from "../assets/images/faq/chrome-microphone.jpg"
import soundIcon from "../assets/images/faq/soundIcon.svg"
import deleteIcon from "../assets/images/faq/deleteIcon.svg"
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import BackLink from "../components/BackLink";
import { getQRKey } from "../helpers/URLHelper";
import NavLogo from "../components/NavLogo";



export default function FAQs () {

  useEffect( () => {
    document.title = 'FAQ | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "Get answers to all your frequently asked questions about Hallmark video greeting cards, from how to edit a video to earning Crown Rewards points.";
      
    TrackingHelper.trackPage();
  }, [] );

  return(

    <div className="screen-wrap" >
      { getQRKey() ? 
        <NavLogo />
        :
        <NavBar />
      }
      <MainContent className="container">
        <BackLink title="Back"/>
        <h1 className="headline-text">FAQs</h1>
        <br/>


        <h4>ACCOUNT LOG IN QUESTIONS</h4>
        <br/>

        <h5>Do I need to sign up?</h5>
        <p>Yes, an account is needed to save, edit, and send your video greeting. But no account is needed to participate in or receive video greetings.</p>
        <br/>
        <h5>Why doesn’t my Hallmark.com Account username and password work?</h5>
        <p>Hallmark Video Greeting Cards are created in a system that is separate from your Hallmark.com account. If you do not currently have an account on Hallmark Video Greeting Cards, you will need to create one.</p>
        <br/>

        <h5>How do I log back into my account?</h5>
        <p>If you are creating a Video Greeting from a physical card, you can rescan your card to continue.</p>
        <p>You can also access your account from any computer or phone. Go to hallmarkvideogreetingcards.com and SIGN IN with your email and password. Your videos that are in progress or completed will be there. You can also download the app on your phone from the app store and SIGN IN.</p>
        <br/>

        <h5>What if I forget my password?</h5>
        <p>Click  on the &ldquo;<a href="/password-reset"><strong>Forgot password?</strong>&rdquo;</a> link from the sign in screen and you will be prompted to provide your email.&nbsp; An option to reset your password will be sent to the email address associated with your account.</p>
        <br/>

        <br/>
        <br/>

        <h4>GENERAL QUESTIONS</h4>
        <br/>

        <h5>Can I do this on my computer? </h5>
        <p>Yes. All your work is saved to an account that can be accessed at any time from either your phone or your computer.  You will be prompted to create an account and you will use that to at <a href="https://www.hallmarkvideogreetingcards.com">Hallmarkvideogreetingcards.com</a> to sign in.</p>
        <br/>

        <h5>How many photos and videos can I add? </h5>
        <p>You can upload as many photos or videos as you would like included! (We recommend uploading no more than 10 photos at a time or 3 videos at a time).</p>
        <br />

        <h5>Can I watch my video before it is final?</h5>
        <p>Yes. You can preview your video as many times as you like while you are creating it. </p>
        <br />

        <h5>Can I use existing videos or photos for my Video Greeting?</h5>
        <p>Yes, you can use photos, videos, or a combination of both for your video greeting. Click “ADD PHOTO OR VIDEO” and then select the “CHOOSE FROM LIBRARY” option.</p>
        <br />

        <h5>Can I add a YouTube video into my Video Greeting?</h5>
        <p>You cannot add a YouTube link video a Video Greeting. YouTube doesn’t allow their videos to be downloaded. You can upload any video that you are able to download and view in your camera roll.</p>
        <br />

        <h5>What video and photo file types are supported in Video Greetings?</h5>
        <ul>
          <li>Videos – .mov, .mp4, .avi</li>
          <li>Photos – .jpg, .png, .gif</li>
        </ul>
        <br />

        <h5>How long can my finished video be? </h5>
        <p>There is no time limit on your final video, so invite as many people as you like!</p>
        <br />

        <h5>Can I change the design of my Video Greeting after my original selection?</h5>
        <p>No, once you have started a Video Greeting and selected your animated template you cannot switch the design without starting over.</p>
        <br />

        <h5>How do I invite people?</h5>
        <p>Once you select your Hallmark Video Greeting Card you can INVITE FRIENDS TO CONTRIBUTE. Tap that button and an invitation will pop-up. You can edit the invitation text by tapping the message. Easily send the invite to multiple people by email or text.</p>
        <br />

        <h5>Do the people I invite to send in videos or photos need to pay?</h5>
        <p>No. There is no cost for someone who is invited to participate.</p>
        <br />

        <h5>I invited the wrong person, can I remove them?</h5>
        <p>Once you share the INVITE link, there’s no way to recall it. However, if anyone submits a video you don’t want to include you can always delete it by hitting the <img src={deleteIcon} alt="Delete Icon" /> icon next to their video.</p>
        <br />

        <h5>What happens if I need to send my Video Greeting and I haven’t heard back from all the invitees?</h5>
        <p>Once you finish a video, no one can add to it. If a Video Greeting has already been finished and an invitee then clicks on the invitation, they will receive a message that says, “The Video Greeting has already been sent.” </p>
        <br />

        <h5>What is the difference between buying a physical card and buying a digital card?</h5>
        <p>When you purchase a physical card, you create the Video Greeting using the QR code that is printed in the card. Once you finished the video, you mail or give the card to the recipient. They will watch their video by scanning that same code inside the card. </p>
        <p>The digital greetings are shared without a physical card. This is great solution when you need to create a greeting quickly or don’t plan to mail a card.  Start by choosing your occasion and animation at hallmarkvideogreetingcards.com or in the Hallmark Video Greeting Cards app. Once created, the finished videos can be sent to the recipient by text, email, and downloaded to save.</p>
        <br />

        <h5>How can I purchase a physical card?</h5>
        <p>You can find Hallmark Video Greeting Cards in most US Hallmark Gold Crown stores nationwide, in all Walmart stores where Hallmark products are sold, and in select Walgreens stores across the country.  You can also find the full offering of Hallmark Video Greeting Cards at Hallmark.com.  Expanded in-store placement will come in early 2022. </p>
        <br />

        <h5>What is the step-by step instructions for creating from the CARD I purchased? </h5>
        <ol>
          <li>Open the card and peel off the perforated tab to reveal the QR code inside. You will need a smart phone to create your video greeting.</li>
          <li>Open your phone’s camera (do not take a picture) and hover over the black and white code provided. Your camera will recognize the code and provide a pop-up message. </li>
          <li>Tap the pop-up message to get started. </li>
          <li>The system will prompt you to CREATE AN ACCOUNT so you can find your project again. </li>
          <li>Next, personalize for the lucky recipient by adding their name in the space provided. </li>
          <li>Next tap ADD PHOTOS OR VIDEOS to begin building your video and adding your photos/videos. </li>
          <li>You can also invite others to send in photos and videos by tapping INVITE FRIENDS TO CONTRIBUTE.</li>
          <li>You can preview your video anytime while creating. Once your Video Greeting is finished, tap It's Finished'. <u>You cannot make any further changes or add more people after it is finished.</u></li>
        </ol>
        <br />

        <h5>How do I know when my physical card is ready to mail?</h5>
        <p>When you are done adding photos and videos and finished collecting videos from others, you will click 'It's Finished' on the preview page. Once you have finished your card, it is ready to mail or hand to the lucky recipient. When they scan the QR code, the video you created will play. </p>
        <br />

        <h5>How do I know my Video Greetings has been finished?</h5>
        <p>When you finish the Video Greeting the edit options will be removed.</p>
        <br />

        <br/>
        <br/>


        <h3>DIGITAL VIDEO GREETING CARD PURCHASE</h3>
        <br/>

        <h5>How can I purchase a digital Hallmark Video Greeting Card?</h5>
        <p>You can purchase a digital card at <a href="https://www.hallmarkvideogreetingcards.com">hallmarkvideogreetingcards.com</a> or through the Hallmark Video Greeting Cards app available on your mobile device.</p>
        <br/>

        <h5>What is the step-by step instructions for creating a digital greeting card?</h5>
        <ol>
          <li>Go to hallmarkvideogreetingcards.com or download the Hallmark Video Greeting Cards app on your mobile device. </li>
          <li>Select the occasion of the Video Greeting you want to make then choose the animated template you like, and tap CREATE. You will be asked to create an account so you can find your project again. </li>
          <li>Next, personalize for the lucky recipient by adding their name when prompted. </li>
          <li>Next tap ADD PHOTOS OR VIDEOS to begin building your video. </li>
          <li>You can also invite others to send in photos and videos by tapping INVITE FRIENDS TO CONTRIBUTE join you. </li>
          <li>You can preview your video anytime while you are creating it. Once your Video Greeting is finished select 'It's Finished' and purchase your Video Greeting. NO FURTHER CHANGES CAN BE MADE AFTER YOU FINISH IT.</li>
        </ol>
        <br/>

        <h5>How do I know when my digital Video Greeting is ready to send?</h5>
        <p>When you are done adding and collecting photos and videos you need to PREVIEW your video and select 'It's Finished'.  You will then be prompted to complete your purchase. (NO FURTHER CHANGES CAN BE MADE AFTER YOU FINISH IT).</p>
        <p>Once you have purchased your Hallmark Video Greeting you will see SHARE options. You can email, post to social media, text, copy the link, or download to a thumb drive or your computer.</p>
        <br/>
        <br/>

        <h4>EDITING YOUR VIDEO GREETING</h4>
        <br/>

        <h5>How can I edit my Video Greeting?</h5>
        <p>While editing is not necessary, if needed or desired, you can edit your Video Greeting in a variety of ways:</p>
        <ul>
          <li>Modify the name originally entered</li>
          <li>Change the background music</li>
          <li>Add more invitees to contribute after you sent original invitations</li>
          <li>Delete a photo or video that was added</li>
          <li>Change the order in which the photos and videos are displayed</li>
          <li>Mute the video sound from a video submitted</li>
          <li>Mute the background music from a video submitted</li>
        </ul>
        <br/>

        <h5>Can I change the animated template of my Video Greeting after my original selection?</h5>
        <p>No, once you have started a Video Greeting and selected your template you cannot switch the design without starting over.</p>
        <br/>

        <h5>I misspelled a name; how do I fix it?</h5>
        <p>Click on the pencil icon in the edit screen and change the name you entered for your greeting.</p>
        <img width="35px" src={pencilIcon} alt="pencil-img"/>
        <br/>
        <br/>

        <h5>How do I change the order of my photos and videos?</h5>
        <p>When you are editing your Video Greeting, hold down the icon on the far-left side of the row containing the photo or video you wish to move.  The icon looks like three lines with up and down arrows above and below the three lines. If using your phone, use your finger to move the video up or down in relation to the other content for your greeting. If using your desktop, use hold down your mouse button and move the video or photo up or down to change the order.</p>
        <img width="35px" src={moveIcon} alt="move-icon" />
        <br/>
        <br/>

        <h5>How do I delete a photo or video?</h5>
        <p>To remove a photo or video that was submitted for your Video Greeting, select the trash can icon that is directly next to the photo or video that you want to remove.</p>
        <img width="35px" src={deleteIcon} alt="delete-icon" />
        <br/>
        <br/>

        <h5>How do I invite more people to contribute to my Video Greeting after I sent my original invitations?</h5>
        <p>To invite other friends and family to contribute photos or videos to your Video Greeting, tap the pencil icon to go back to your project and tap on the “INVITE FRIENDS TO CONTRIBUTE” option. You can add people until your video has been finished.</p>
        <br />

        <h5>Can I change how long a photo is displayed in the Video Greeting?</h5>
        <p>All photos display for 4 seconds. Research has determined this is the optimal time for a photo to show.</p>
        <br />

        <h5>Why doesn’t my video fill the screen in my Video Greeting?</h5>
        <p>Recording your videos with your mobile device turned sideways (landscape) provide the best videos that fill the screen for the recipient.</p>
        <br />

        <br />

        <h4>SOUND QUESTIONS</h4>
        <br />

        <h5>Can I add my own music to my Video Greeting?</h5>
        <p>Unfortunately. you cannot add your own music to your Video Greeting due to copyright issues.  We do, however, have numerous music options to choose from. </p>
        <br />

        <h5>How do I select the music I want with my Video Greeting?</h5>
        <p>After choosing a Video Greeting, the system will select some default background music to play. To change the song, click on the displayed song to see a drop-down menu. You can scroll through music choices and change the music to one of the many options provided. </p>
        <br />

        <h5>I can’t hear anything when I’m playing the video.</h5>
        <p>Check your phone’s ringer. For Apple phones, there are multiple speakers. Your volume and sound may be on, but you will not hear your video unless your ringer is on. </p>
        <br />

        <h5>How do I change the background music I originally selected?</h5>
        <p>Click on the pencil icon in the edit screen. This will take you back to the screen where you can select the drop-down menu to choose different music.</p>
        <img width="35px" src={pencilIcon} alt="sound-icon" />
        <br/>
        <br/>

        <h5>How do I change the sound options for my Video Greeting?</h5>
        <p>Videos will automatically play the video sound with the background music. You can change the sound options when editing your Video Greeting by clicking on the speaker icon next to each video. There are three sound options: play background music and video sound (default), mute video sound, mute background music.  For instance, if someone is singing HAPPY BIRTHDAY you may want to mute the background music for that individual video.</p>
        <img width="35px" src={soundIcon} alt="sound-icon" />
        <br/>
        <br/>

        <br />

        
        <h4>FINISH YOUR VIDEO GREETING</h4>
        <br />

        <h5>What do I do when I have all my photos and videos loaded and ready to send?</h5>
        <p>Your Video Greeting needs to be “finished” before sending. To finish your Video Greeting, first preview your greeting. If you have no further edits, select the 'It's Finished' option.</p>
        <p style={{color: "#FF0000"}}>IMPORTANT NOTE: You cannot make any further changes and no one else can submit their photos or videos once you finish your Video Greeting. Make sure it is exactly the way you want it before completing this process!</p>
        <br/>


        <h4>SENDING AND SHARING YOUR VIDEO GREETING</h4>
        <br/>

        <h5>How do I send my Video Greeting?</h5>
        <ul>
          <li>Physical card &ndash; After you have finished your greeting, you can simply mail your card to the recipient.&nbsp; When they scan the QR code in the card from their phone, their Video Greeting will play.</li>
          <li>Digital card &ndash; You will be prompted to share your Video Greeting after you have finished your greeting. Share by Email, Text, or download.</li>
        </ul>
        <br/>

        <h5>Is there a time limit for a recipient to download a Video Greeting?</h5>
        <p>A recipient has 6 months to download their Video Greeting if they want to save it.</p>
        <br/>

        <h5>How do I download my Video Greeting?</h5>
        <p>When someone receives a Video Greeting, they will see a link to “Download Video” directly below the video they play.  Click on this link to download the video. The video will be downloaded to the default location for downloads for your phone or computer depending on which device you are using.</p>
        <br/>

        <h5>I keep downloading my video, but I can’t find it.</h5>
        <p>If you are on an Apple phone your downloads are going to you FILES folder on your phone. Open this folder and look in “downloads.” Once you find the video in that download folder you can tap it and save it to your phone gallery, so it is easier to access.</p>
        <br />

        <h5>Can I post my Video Greeting on social media?</h5>
        <p>If you want to post your Video Greeting to any social media platform, download the greeting first and then upload it to your post. </p>
        <br/>

        <h5>How can I display or project my Video Greeting on a television?</h5>
        <p>You can download your Video Greeting Card on to a thumb drive and if your TV supports that function. You can also connect your computer to your TV screen with HDMI cables or screen cast through apple TV. </p>
        <p>Here are instructions for Apple Airplay: <a href="https://support.apple.com/en-us/HT204289">https://support.apple.com/en-us/HT204289</a></p>
        <p>Here are instructions for Chromecast:&nbsp; <a href="https://support.google.com/chromecast/answer/6006232?hl=en">https://support.google.com/chromecast/answer/6006232?hl=en</a></p>
        <br/>

        <h5>How can I make my video play continuously (on a loop)?</h5>
        <p>On the video in your browser: RIGHT CLICK and check LOOP.</p>
        <br/>

        <h5>What are the Terms of Service and Privacy Policy for Video Greetings?</h5>
        <p>The Terms of Service and Privacy Policy are provided in links in the footer of every screen the customer sees.</p>
        <img width="350px" src={terms} alt="Terms" />
        <br />
        <br />

        <h5 id='micHelp'>How to ensure your microphone is enabled</h5>
        <ol>
          <li>Go to Settings</li>
          <li>Scroll down to your Chrome App</li>
          <li>Toggle Microphone on</li>
        </ol>
        <img width="350px" src={chromeSettingsIcon} alt="chrome-settings-icon" />
        <br/>
        <br/>

      </MainContent>
      <Footer hideMobile={true} />
    </div>
  );
}