import React, { Component } from 'react';
import MenuCloseIcon from "../assets/images/HMK-Icons/Icons_Menu_Close_Bold.svg";
import { Button, Modal } from "react-bootstrap";

export default class StandardModal extends Component {

	constructor(props, info) {
		super(props, info);
	}

	onHide = () => {
		if( this.props.onHide ) {
			this.props.onHide();
		}
	}
	confirm = () => {
		if( this.props.onConfirm ) {
			this.props.onConfirm();
		}
	}

	render() {


		if( !this.props.show ) {
			return null;
		}


		return (
			<Modal
			size={this.props.size}
            show={this.props.show}
            onHide={this.onHide}
          	>
	            <Modal.Header className="text-left">
	              <div className="icon-box" onClick={this.onHide}>
	                <span className="close">
	                  <img src={MenuCloseIcon} alt="Close" />
	                </span>
	              </div>

	              <h3 className="headline">{this.props.header}</h3>
	            </Modal.Header>

	            <Modal.Body>
					{this.props.children}
	            </Modal.Body>

				<Modal.Footer>
					{this.props.confirmBtnText && 
						<button
						className={"btn btn-block "+(this.props.confirmBtnType=='danger'?'btn-danger':'btn-primary')}
						onClick={this.confirm}
						>
						{this.props.confirmBtnText}
						</button>
					}
					{ this.props.closeBtnText!==false &&
						<button
						className="btn btn-outline-primary btn-block"
						onClick={this.onHide}
						>
						{this.props.closeBtnText || 'CANCEL'}
						</button>
					}
				</Modal.Footer>
			</Modal>
		);
	}
 }
