import React, { useState }  from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Notify from "./Notify";
import { getInviteFizzUUID, isMobile } from "../helpers/URLHelper";


const AddButtonGroup = ({ fizzId, onFileUploadChange, isInvite, errorsCleared }) => {

  const [isChromeMicDisabled, setIsChromeMicDisabled ] = useState( false );
  const [showDropdown, setShowDropdown] = useState( false );
  
  const mobileLabel = isInvite ? "btn btn-primary btn-upload btn-block btn-lg-inline-block mr-2" : "btn btn-outline-primary btn-upload btn-block btn-lg-inline-block mr-2";

  const checkMic = (e) => {
    try{
      var isChromeIOS = /CriOS/.test(navigator.userAgent);

      // if mobile check if microphone is enabled for video uploads.
      if( isMobile() && isChromeIOS ){
        checkMicEnabled();
      }
    } catch(e){}
  }

  const checkMicEnabled = () => {
    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
      console.error( 'Browser does not implement mediaDevices.' );
      return;
    }
    setTimeout(()=>{
      navigator.mediaDevices.getUserMedia({ audio: true
      })
      .then( (stream) => { 
        setIsChromeMicDisabled( false );
      })
      .catch( ( error ) => { 
        setIsChromeMicDisabled( true );
      })
    },200)
  }

  return(  
      <div>
        { isMobile() ? (
          <label 
            className={mobileLabel}
            onClick={checkMic} >
          ADD PHOTO OR VIDEO
            <input
              id="file"
              type="file"
              onChange={onFileUploadChange}
              required
              multiple
            />
          </label>
        ) : (
          <Dropdown
            drop="up"
            show={showDropdown}
            id={'addMediaDropdown'}
            onToggle={()=> setShowDropdown( !showDropdown ) }
            className="btn-lg-inline-block btn-block"
          >
            <Dropdown.Toggle
              variant={ isInvite ? "primary": "outline-primary"}
              className="btn-lg-inline-block btn-block btn-group dropup d-inline-block mr-lg-2 mt-lg-2"
              >
              ADD PHOTO OR VIDEO
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <label className="dropdown-item dropdown-link btn-upload">
                  Choose from library
                  <input
                    id="file"
                    type="file"
                    onChange={onFileUploadChange}
                    required
                    multiple
                    onClick={()=> setShowDropdown(!showDropdown)}
                    accept="image/*, video/*"
                    />
                </label>
                <Link
                  to={ isInvite ? `/in/${getInviteFizzUUID()}/record/${fizzId}` : `/record/${fizzId}`}
                  className="dropdown-item text-left"
                  style={{ lineHeight: "22px" }}
                >
                  Record a video
                </Link>

            </Dropdown.Menu>
          </Dropdown>
        )}

        { isChromeMicDisabled && 
          <div className="mt-4 mb-4">
            <Notify 
              message={<span>Sorry. We are unable to verify your microphone is on. Please watch your video after recording. If your recording does not have sound <a href="/faq#micHelp" style={{fontWeight: "bold", color: "white", textDecoration: "underline"}}>use these steps to turn on your microphone.</a></span>}
              error= {true}
              onHide={()=>setIsChromeMicDisabled( false )}
              noNewLines={true}
            />
          </div> 
        }

        { !isInvite &&
          <Link
            to={"/invite-others/" + fizzId}
            className="btn btn-outline-primary btn-block btn-lg-inline-block mt-lg-2"
          >
            invite friends to&nbsp;contribute
          </Link>
        }

        { isInvite && errorsCleared && 
          <>
            <Link
              to={"/view-invite-thank-you"}
              className="inviteContinueButton btn btn-outline-primary ml-lg-6 mt-lg-2" 
              >
              Continue
            </Link>
            <div className="mt-3"></div>
          </>
        }
      </div>
    );
}

export default AddButtonGroup;