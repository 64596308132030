import React, { useEffect, useState } from 'react';

const CommentForm = (props) => {

  const blankComment = {
    parentUUID: null,
    body: '',
    firstName: '',
    lastName: ''
  };
  const [comment, setComment] = useState(blankComment);

  useEffect(() => {
    props.parentUUID && setComment(prevComment => ({
      ...prevComment,
      parentUUID: props.parentUUID
    }));

    return () => {
      setComment(blankComment);
    }
  }, [props.parentUUID]);

  const handleChange = (e) => {
    const target = e.target;
    setComment(prevComment => ({
      ...prevComment,
      [target.name]: target.value
    }));
  }

  const validate = () => {
    if (comment.firstName.length === 0) {
      return false;
    }
    if (comment.lastName.length === 0) {
      return false;
    }
    if (comment.body.length < 5) {
      return false;
    }
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;
    // return console.log(comment);
    props.handleSubmit(comment);
    setComment(blankComment);
  }

  return (
    <form className="d-flex flex-column w-100" onSubmit={handleSubmit}>
      <div className="form-group d-flex">
        <span className="has-float-label mr-2">
          <label htmlFor="firstName">First Name</label>
          <input 
            className="form-control"
            name="firstName"
            value={comment.firstName}
            onChange={handleChange}
          />
        </span>
        <span className="has-float-label">
          <label htmlFor="lastName">Last Name</label>
          <input 
            className="form-control"
            name="lastName"
            value={comment.lastName}
            onChange={handleChange}
          />
        </span>
        {props.type === "reply" && <span className="cancel-link ml-auto p-2 align-self-start">
          <div className="btn btn-link p-0 text-decoration-none" onClick={props.handleReplyCancel}>
            <i class="fa fa-times-circle fa-lg" aria-hidden="true"></i>
          </div>
        </span>}
      </div>
      <input
        className="form-control comment-input"
        name="body" 
        placeholder={`Leave a ${props.type}...`}
        onChange={handleChange}
        value={comment.body}
      />
      <button type='submit' style={{display: 'none'}}>Submit</button>
    </form>
  );
}

export default CommentForm;