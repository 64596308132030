class TrackingHelper {

  trackPage(){
    console.log( "trackPage",window.location.pathname );
    try {

      if( window._satellite ) {
        console.log( "window._satellite track");
        window._satellite.track('page');

      } else {
        window.addEventListener('load', ()=>{
          console.log( "window.load track");
          try {
            window._satellite.track('page');
          } catch(e){
            console.error(e);
          }
        });
      }
      
    } catch(e) {
      console.error( e );
    }

  }

  trackPreview(){
    try {
      if( window._satellite ) {
        window._satellite.track( 'videoPreview' );

      } else {
        window.addEventListener('load', ()=>{
          try{
            window._satellite.track('videoPreview');
          } catch(e){
            console.error(e);
          }
        });
      }
    } catch(e) {
        console.error( e );
    }
  }

}

export default new TrackingHelper()
