import React from "react";
import { Link } from "react-router-dom";
import { isLoggedIn, logOut } from "../helpers/APIHelper";
import Constants from "../helpers/Constants";

function FindACard( props ) {

  var createLink = "/browse";
  var headline = 'Want to create another Video Greeting?';
  var subheadline = "You can send one today by email, or you can mail your Video Greeting inside a real Hallmark card.";
  var createDigitalButton = "Create a Digital Greeting";
  var shopLink = Constants.HALLMARK_LANDING_PAGE;

  if( props.isInvite ) {
    createLink = "/";
    headline = 'Want to create your own Video Greeting?';
    shopLink = Constants.HALLMARK_LANDING_FROM_INVITE;
  }

  if( props.isDigitalThankYou ) {
    subheadline = "Send another one today, or you can mail your Video Greeting with a real Hallmark card.";
    createDigitalButton = "Start a new greeting";
    shopLink = Constants.HALLMARK_LANDING_FROM_DIGITAL_THANK_YOU;
  }

  if( props.isQRBoxCard ) {
    headline = "Shop more Video Greetings"
    subheadline = "Get ready for birthdays and more upcoming occasions.";
    createDigitalButton = "Start a new greeting";
  }

  return (
    <div>
      <h2 className="headline-22">{headline}</h2>
      <h5 className="subheadline">{subheadline}</h5>
      <div>
        <a className="btn btn-primary btn-block btn-lg-inline-block mr-4" href={createLink} >{createDigitalButton}</a>
        
        <a className="btn btn-outline-primary  btn-block btn-lg-inline-block" href={shopLink}>shop the cards</a>
      </div>
    </div>
  );
}

export default FindACard;
