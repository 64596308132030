import React, { Component } from "react";
import ReactDOMServer from 'react-dom/server';
import { apiFetch, generateDeviceId, isLoggedIn, saveSession  } from "../helpers/APIHelper";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import "../assets/css/SignIn.css";
import { getQRKey, getBaseURL} from "../helpers/URLHelper";
import loginImgPhysical from "../assets/images/account-side.jpg";
import { toastManager } from "../components/Toaster";
import loginImg from "../assets/images/video-greetings-digital-sign-in.jpg";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import Spinner from "../components/Spinner";
import BannerBar from "../components/BannerBar";
import TrialAccountBanner from "../components/TrialAccountBanner";
import ThemeIcons from '../assets/images/invite-carousel-solid.png';

var EMAIL_REGEX = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);



class Register extends Component {
  constructor(props) {
    super(props);

    var redirect = null;
    var redirectEncoded = "";
    if ( props.match ){
      redirectEncoded = props.match.params.redirect;
      redirect = decodeURIComponent( redirectEncoded || "");
    }

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      marketingOptIn: true, // on all the time
      input: {},
      errors: {},
      redirect: redirect,
      redirectEncoded: redirectEncoded,
      isQRCard: false,
      isSubmittingRegister: false,
    };
  }

  componentDidMount() {
    if( this.props.blockRedirectQR ){
      this.setState( { isQRCard: true } );
    } else if ( this.props.isTrialAccount ){

      this.setState({ 
        isTrialAccount: true,
      });
      this.getOfferUserData();

    } else if ( this.props.isShareOffer ){

      this.setState({ 
        isShareOffer: true,
      });
      this.getOfferUserData();

    } else {
      isLoggedIn() && this.props.history.push("/");
    }


    document.title = 'Create an Account | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    TrackingHelper.trackPage();
  }

  getOfferUserData = () => {
    this.setState({
      loading: true,
    });

    apiFetch(
      this.props.isTrialAccount ? "/rest/user/actor-info?result=interested" : "rest/offer/info?page=register",
      {
        method: "GET",
      },
      (json) => {

        this.setState({
          loading: false,
          offer: json.offer,
          firstName: json.firstName || "",
          lastName: json.lastName || "",
          email: json.email || "",
          confirmEmail: json.email || "",
          // themeImg: json.themeImg
        });
      },
      (error) => {
        console.log(error);
        this.setState({
          loading: false,
          error: error.message + ""
        })
      }
    );
  };


  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {

    var value = event.target.value;
    if( event.target.type=='checkbox' ){
      value = event.target.checked;
    }
    this.setState({
      [event.target.name]: event.target.value,
      errors:{}
    });

    console.log("Handle Change", event.target.name, value );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({ 
      loginErrorMessage: null,
    });

    if (this.validate()) {
      this.setState({ 
        isSubmittingRegister: true
      });

      var from = null;

      if( this.state.isTrialAccount ){
        from = 'actor';
      } else if ( this.state.isShareOffer ){
        from = 'recipient';
      }

      var data = {
        qrKey: getQRKey(),
        version: "web",
        from: from,
        user: {
          deviceType: 3, // 3 == web
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          password: this.state.password,
          marketingOptIn: this.state.marketingOptIn,
          offer: this.state.offer,
          deviceId: generateDeviceId(),
          userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
      };
      apiFetch(
        "/rest/user/register-react?type=web&screen=register",
        {
          method: "POST",
          data: data,
        },
        (json) => {
          saveSession(json);
          
          toastManager.showToast({
            title: 'Account created!' ,
            message: 'You’ll receive an email confirming your new Hallmark Video Greetings account.'
          });

          this.setState({
            isSubmittingRegister: false
          });

          if( json.editFizzId ) {
            this.props.history.push( '/edit/' + json.editFizzId )
          } else if ( json.createTaId ){
            this.props.history.push( getBaseURL('/create/' + json.createTaId) )
          } else if( this.state.redirect ) {
            this.props.history.push( this.state.redirect )
          } else if ( this.state.isTrialAccount || this.state.isShareOffer ){
            this.props.history.push( '/browse' )
          } else if( json.hasFizzes ) {
            this.props.history.push( '/list' )
          } else {
            this.props.history.push( '/' )
          }

        },
        (error) => {
          console.log("Error::", error);
          this.setState({
            loginErrorMessage: error ? error.message : error + "",
            isSubmittingRegister: false
          });
        }
      );
      console.log(this.state);

      let input = {};
      input["name"] = "";
      input["email"] = "";
      input["password"] = "";
      input["confirm_password"] = "";
      this.setState({ input: input });
    } 

    console.log(this.state.errors);
  };

  disableSubmitButton = () => {
    // first check each form field is not null
    if( !this.state.firstName || !this.state.lastName || !this.state.email || !this.state.confirmEmail || !this.state.password || !this.state.confirmPassword ){
      return true;
    }

    // then make sure each field has a value 
    if ( this.state.firstName.length < 1 || this.state.lastName.length < 1 || this.state.email.length < 1 || this.state.confirmEmail.length < 1 || this.state.password.length < 1 || this.state.confirmPassword.length < 1 ){
      return true;
    }

    // finally check if submitting 
    return this.state.isSubmittingRegister;
  }

  validateFirstName = () =>{
    if (!this.state.firstName) {
      const errors = {firstName : "Please enter your first name."};
      this.setState(() => ({errors}));
    }
    else{
      this.setState(() => ({errors: {}}));
    }
  }

  validateLastName = () =>{
    if (!this.state.lastName) {
      const errors = {lastName : "Please enter your last name."};
      this.setState(() => ({errors}));
    }
    else{
      this.setState(() => ({errors: {}}));
    }
  }

  validateEmail = () =>{

    if (!this.state.email) {
      const errors = {email : "Please enter your email address."};
      this.setState(() => ({errors}));
    }
    else if(!EMAIL_REGEX.test(this.state.email)){
        const errors = {email : "Please enter valid email address."};
        this.setState(() => ({errors}));
      }
    else{
      this.setState(() => ({errors: {}}));
    }
  }

  validateConfirmEmail = () =>{
    if (!this.state.confirmEmail) {
      const errors = {confirmEmail : "Please confirm your email address."};
      this.setState(() => ({errors}));
    }
    else if( (this.state.email+"").toLowerCase() != this.state.confirmEmail.toLowerCase()){
      const errors = {confirmEmail : "Confirm email doesn't match."};
      this.setState(() => ({errors}));
    }
    else{
      this.setState(() => ({errors: {}}));
    }
  }

  validatePassword = () =>{
    if (!this.state.password) {
      const errors = {password : "Please enter your password."};
      this.setState(() => ({errors}));
    }
    else if(this.state.password.length < 6){
      const errors = {password : "Please enter a password of at least 6 characters."};
      this.setState(() => ({errors}));
    }
    else{
      this.setState(() => ({errors: {}}));
    }
  }

  validateConfirmPassword = () =>{
    if (!this.state.confirmPassword) {
      const errors = {confirmPassword : "Please confirm your password."};
      this.setState(() => ({errors}));
    }
    else  if (
      typeof this.state.password !== "undefined" &&
      typeof this.state.confirmPassword !== "undefined"
    )
    if(this.state.password != this.state.confirmPassword){
      const errors = {confirmPassword : "Passwords don't match."};
      this.setState(() => ({errors}));
    }
    else{
      this.setState(() => ({errors: {}}));
    }
  }


  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!this.state.firstName) {
      isValid = false;
      errors.firstName = "Please enter your first name.";
    }

    if (!this.state.lastName) {
      isValid = false;
      errors.lastName = "Please enter your last name.";
    }

    if (!this.state.email) {
      isValid = false;
      errors.email = "Please enter your email address.";
    }

    if (typeof this.state.email !== "undefined") {
      if (!EMAIL_REGEX.test(this.state.email)) {
        isValid = false;
        errors.email = "Please enter valid email address.";
      }
    }

    if (!this.state.confirmEmail) {
      isValid = false;
      errors.confirmEmail = "Please enter your confirm email address.";
    }

    if( (this.state.email+"").toLowerCase() != this.state.confirmEmail.toLowerCase()){
      isValid = false;
      errors.confirmEmail = "The emails you entered don't match. Please check them and try again.";
    } 

    if (this.state.password.length < 6 ) {
      isValid = false;
      errors.password =
        "Please enter a password of at least 6 characters.";
    }
    
    if (!this.state.password) {
      isValid = false;
      errors.password = "Please enter your password.";
    }

    if (!this.state.confirmPassword) {
      isValid = false;
      errors.confirmPassword = "Please enter your confirmation password.";
    }

    if (
      typeof this.state.password !== "undefined" &&
      typeof this.state.confirmPassword !== "undefined"
    ) {
      if (this.state.password != this.state.confirmPassword) {
        isValid = false;
        errors.confirmPassword = "The passwords you entered don't match. Please check them and try again.";
      }
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }

  render() {
    return (
      <div className="screen-wrap">
        { ( getQRKey() || this.state.isTrialAccount ) ? 
          <NavLogo />
          :
          <NavBar />
        }
        { this.state.isTrialAccount &&
          <BannerBar />
        }
        <MainContent className="container bg-margin">
          { this.state.loading ?
            <Spinner float={true} />
            :
            this.renderContent()
          }
        </MainContent>
        <Footer />
      </div>
    );
  }

  renderContent() {
    var styles = {
      welcomeBg: {
        backgroundColor: "#eeeeee",
      },
    };


    var scroll = ReactDOMServer.renderToStaticMarkup(
      <div class="marquee">
        <div class="marquee__inner">
          <img src={ThemeIcons} />
          <img src={ThemeIcons} />
          <img src={ThemeIcons} />
          <img src={ThemeIcons} />
        </div>
      </div>   
    );

    var claimMarkup = null;
    if( this.state.offer &&  this.state.offer.ui && this.state.offer.ui.claimMarkup ) {
      claimMarkup = this.state.offer.ui.claimMarkup.replace('SCROLLING_ICONS',scroll)
    }
    // claimMarkup = ReactDOMServer.renderToStaticMarkup(
    //   <>
    //   <h1 className="headline-text">Start your free greeting within 30 days</h1>
    //   <p>*Digital Delivery only (via email or text)</p>
    //   <div dangerouslySetInnerHTML={{__html:scroll}} />
    //   <h2 className="headline-offer mb-2">Enter your information and you can create one Hallmark Video Greeting for any occasion!</h2>
    //   </>
    // );
    console.log(JSON.stringify(claimMarkup));


    return (
      <div className="row">
            <div className="col-md-6 pt-2">
              <form className="sign-form" onSubmit={this.handleSubmit}>


                { this.state.offer ? (
                  <>
                    { claimMarkup ? (
                        <div dangerouslySetInnerHTML={{__html:claimMarkup}} />
                    ) : (
                      <h1 className="headline-text">{ ( this.state.offer.ui && this.state.offer.ui.signUpText ) || "Create a FREE account so your offer is saved:"}</h1>
                    )}
                    {this.state.offer.ui && this.state.offer.ui.signUpScroll &&
                      <div class="marquee mt-2">
                        <div class="marquee__inner">
                          <img src={ThemeIcons} />
                          <img src={ThemeIcons} />
                          <img src={ThemeIcons} />
                          <img src={ThemeIcons} />
                        </div>
                      </div>     
                    }
                  </>
                ) : (
                  <>
                    <h1 className="headline">Create an account so we can save your project</h1>
                    <h6 className="subheadline mb-3">
                      If you already have a Hallmark.com account, please
                      create a new account for Hallmark Video Greetings.
                    </h6>
                  </>
                )}
                    <h6 className="subheadline mb-3">
                      Already have a Hallmark Video Greetings account?
                      &nbsp; 
                      { ( this.state.isTrialAccount || this.state.isShareOffer ) ? (

                        <Link className="blue-link" to={this.state.isTrialAccount ? "/view-invite-trial-login" : "/offer-login" } >
                          Sign in
                        </Link>

                      ) : (

                        <Link className="blue-link" to={this.state.redirectEncoded ? getBaseURL('/login/'+this.state.redirectEncoded) : getBaseURL("/login") } >
                          Sign in
                        </Link>
                      )}
                    </h6>
                <div className="row dflex">
                  <div className="col-md-6">
                    <div className={`form-group ${this.state.errors.firstName ? "invalid": ""}`}>
                      <span className="has-float-label">
                        <label for="firstName"> First name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="firstName"
                          value={this.state.firstName}
                          onBlur ={this.validateFirstName}
                          onChange={this.handleChange}
                          id="firstName"
                        />
                      </span>
                      <small className="text-danger">
                        {this.state.errors.firstName}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={`form-group ${this.state.errors.lastName ? "invalid": ""}`}>
                      <span className="has-float-label">
                        <label for="lastName">Last name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="lastName"
                          value={this.state.lastName}
                          onBlur ={this.validateLastName}
                          onChange={this.handleChange}
                          id="lastName"
                        />
                      </span>
                      <small className="text-danger">
                        {this.state.errors.lastName}
                      </small>
                    </div>
                  </div>
                </div>

                <div className={`form-group ${this.state.errors.email ? "invalid": ""}`}>
                  <span className="has-float-label">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={this.state.email}
                      onBlur ={this.validateEmail}
                      onChange={this.handleChange}
                      id="email"
                      required
                    />
                  </span>
                  <small className="text-danger">
                    {this.state.errors.email}
                  </small>
                </div>
                <div className={`form-group ${this.state.errors.confirmEmail ? "invalid": ""}`}>
                  <span className="has-float-label">
                    <label for="Confirm Email">Confirm email</label>
                    <input
                      type="email"
                      name="confirmEmail"
                      className="form-control"
                      value={this.state.confirmEmail}
                      onBlur ={this.validateConfirmEmail}
                      onChange={this.handleChange}
                      id="confirmEmail"
                      required
                    />
                  </span>
                  <small className="text-danger">
                    {this.state.errors.confirmEmail}
                  </small>
                </div>

                <div className={`form-group ${this.state.errors.password ? "invalid": ""}`}>
                  <span className="has-float-label">
                    <label for="Password">Password</label>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      value={this.state.password}
                      onBlur ={this.validatePassword}
                      onChange={this.handleChange}
                      id="password"
                      required
                    />
                  </span>
                  <small className="text-danger">
                    {this.state.errors.password}
                  </small>
                  <small className="form-text text-muted">
                    6 characters minimum
                  </small>
                </div>
                <div className={`form-group ${this.state.errors.confirmPassword ? "invalid": ""}`}>
                  <span className="has-float-label">
                    <label for="Confirm Password">Confirm password</label>
                    <input
                      className="form-control"
                      type="password"
                      name="confirmPassword"
                      value={this.state.confirmPassword}
                      onBlur ={this.validateConfirmPassword}
                      onChange={this.handleChange}
                      id="confirmPassword"
                      required
                    />
                  </span>
                  <small className="text-danger">
                    {this.state.errors.confirmPassword}
                  </small>
                  <small className="text-danger">
                    {this.state.errors.passwordmatch}
                  </small>
                  <small className="form-text text-muted">
                    6 characters minimum
                  </small>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-3">
                  <small>
                   By creating a Hallmark Video Greetings account, I confirm that I have read and agree to the
                   <Link className="blue-link ml-1 mr-1" to={getBaseURL("/terms")}>
                   Hallmark Terms of Use
                   </Link>
                   and
                   <Link className="blue-link ml-1" to={getBaseURL("/privacy-policy")}>
                    Privacy Policy
                   </Link>, and agree that  Hallmark and its companies may email me about special offers and promotions. I understand that I can change my email preferences at any time.
                  </small>
                  </div>
                </div>



                <div className="row pt-3 align-items-center justify-content-center ">
                  <div className="text-center ">
                    <button
                      type="submit"
                      className="text-uppercase btn btn-primary mt-3"
                      disabled={ this.disableSubmitButton() }
                    >
                      { ( this.state.isTrialAccount || this.state.isShareOffer  ) ? "Choose a free greeting" : "Create Account" }
                    </button>
                  
                  </div>
                </div>

                {this.state.loginErrorMessage && (
                  <p className="alert alert-danger">
                    {this.state.loginErrorMessage}
                  </p>
                )}

                { this.state.isSubmittingRegister &&
                  <Spinner float={true} />
                }
                
              </form>
            </div>
            <div className="col-md-6 text-center d-none d-md-block pt-4">
                <video className="disable-gtm"
                  loop
                  muted
                  autoPlay
                  playsInline
                  width="100%"
                  // onLoad={(e)=>{e.currentTarget.play()}}
                ><source src="https://static.hallmarkvideogreetingcards.com/pub/landing-page-digital-hero-28cq-v2.mp4" type="video/mp4" /></video>
                {/*<img className="w-100 mt-4" src={loginImg} alt="Make Moments Last A Lifetime"/>*/}
            </div>
          </div>
      );
  }
}

export default Register;
