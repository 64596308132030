import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Plyr from "react-plyr";
import "../assets/css/LandingPage.css";
import "react-multi-carousel/lib/styles.css";
import StandardModal from "../modal/StandardModal";
import VideoBGSoTheColorsMatch from "../assets/videos/hero-color-230.mp4";

export class LandingBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoPlay: null
    };
  }

  onGetStarted = () => {
    window.location = '/browse';
  }

  onWatchHowTo = () => {
    this.setState({
      videoPlay: true,
    });
  }

  closeVideoPlay = ()=>{
    this.setState({
      videoPlay: null,
    });
  }

  render() {

    var headline = 'A new way to make their day';
    var message = 'Upload your photos and videos to create a one-of-a-kind video greeting. Then send it instantly via text or email for them to save and share.';

    var bgColor = 'https://static.hallmarkvideogreetingcards.com/pub/landing-page-digital-hero-lynn-bg.mp4';
    var sideVideo = 'https://static.hallmarkvideogreetingcards.com/pub/landing-page-digital-hero-bday-lynn.mp4';
    if( this.props.isQRCard ) {
      bgColor = VideoBGSoTheColorsMatch;
      message = 'Introducing cards that let you easily add personalized photos and videos—and invite others to join in. Then send it so they can enjoy, save and share.';
      sideVideo = 'https://static.hallmarkvideogreetingcards.com/pub/landing-page-physical-hero-28cq-v2.mp4';
    }

      return(
        <section className="hero p-0">
          <video className="hero-bg-video disable-gtm" 
                  loop={true}
                  muted={true}
                  autoPlay
                  playsInline ><source src={bgColor} type="video/mp4" /></video>
          <div className="container-fluid p-0 container-lg" >
            <div className="row no-gutters">
              <div className="col-md-8">
                <div className={"hero__text "+(this.props.isQRCard?'hero__text__qr':'hero__text__digital')} >
                  <h2 className="headline">{headline}</h2>
                  <p className="hero__description mt-4">{message}</p>
                  {
                    this.props.isQRCard?
                      <button 
                        className="btn btn-outline-primary btn-block btn-lg-inline-block" 
                        onClick={this.onWatchHowTo} 
                        style={{ border: 'none' }}
                      >
                      SEE HOW IT WORKS
                      </button>
                      :
                      <button className="btn btn-primary" onClick={this.onGetStarted} >GET STARTED</button>
                  }
                </div>
              </div>

              <div className="col-md-4 d-md-block hero__image">
                <video className="disable-gtm"
                  style={{zIndex:100}}
                  loop
                  muted
                  autoPlay
                  playsInline
                  width="100%"
                  // onLoad={(e)=>{e.currentTarget.play()}}
                ><source src={sideVideo} type="video/mp4" /></video>
              </div>
            </div>
          </div>


          <StandardModal
            size='lg'
            show={this.state.videoPlay}
            onHide={this.closeVideoPlay}
            closeBtnText={false} >
              <Plyr
                type="video" // or "vimeo"
                url='https://static.hallmarkvideogreetingcards.com/pub/how-to-video-greeting-card-v1-30cq.mp4'
                autoplay
                options={{playsinline:true}}
              />
          </StandardModal>
        </section>
      );
    }
}

export default LandingBanner;
