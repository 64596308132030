import React from "react";
import { Link } from "react-router-dom";
import { isLoggedIn, logOut } from "../helpers/APIHelper";
import { getBaseURL } from "../helpers/URLHelper";

function Footer( props ) {

  var year = (new Date()).getFullYear();
  var hideMobile = ''; //props.hideMobile? 'd-none d-lg-block' : '';
  var stickyFooter = props.sticky ? "sticky" : '';
  
  return (
    <footer className={ hideMobile+" "+(props.className||"")+" "+stickyFooter }>
      <ul className="footer_inner">
        <li className="d-none d-lg-block hide-in-nav" >
          <Link to={getBaseURL("/faq")}> FAQ</Link>
        </li>
        {isLoggedIn() && (
          <>
            <li className="d-none d-lg-block hide-in-nav" >
              <Link to={getBaseURL("/change-password")}> Change Password</Link>
            </li>
          </>
        )}

        <li className="d-none d-lg-block hide-in-nav" >
          <Link to={getBaseURL("/tutorials")}> Tutorials</Link>
        </li>
        <li>
          <Link to={getBaseURL("/terms")}> Terms of Service</Link>
        </li>
        <li>
          <Link style={{ borderRight: "none" }} to={getBaseURL("/privacy-policy")}>
            Privacy Policy
          </Link>
        </li>
      </ul>

      <div className="text-center copy_right">
        Need help? Call <a href="tel:+18775808847" style={{ borderRight: "none" }}> 1-877-580-8847</a>
      </div>
      
      <div className="text-center copy_right">
        Copyright &copy; {year} Hallmark
      </div>
    </footer>
  );
}

export default Footer;
