import React, { Component } from "react";
import { Link } from "react-router-dom";
import settings from "../assets/images/HMK-Icons/Icons_Settings_Regular.svg";
import arrowright from "../assets/images/HMK-Icons/Icons_Arrows_Caret_Right_Bold.svg";
import menuclose from "../assets/images/HMK-Icons/Icons_Menu_Close_Bold.svg";
import { isLoggedIn, logOut } from "../helpers/APIHelper";
import Footer from "./Footer";
import signouticon from "../assets/images/HMK-Icons/Icons_Sign_Out_Regular.svg";
import signInIcon from "../assets/images/HMK-Icons/Icons_Person_Regular.svg";
import contactIcon from "../assets/images/HMK-Icons/Icons_Phone_Smartphone_Regular.svg";
import { Navbar, Container, Nav } from 'react-bootstrap';


const listeners = {
  onToggle:null
};

export const onToggle = (fun)=>{
  listeners.onToggle = fun;
}

class NavBar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isExpanded:false
    };
  }


  onToggle = ()=>{
    this.setExpanded(!this.state.isExpanded);
  }

  closeFromClick = ()=>{
    this.setExpanded(false);
  }

  setExpanded = (isExpanded)=>{

    this.setState({
      isExpanded:isExpanded
    })

    if( listeners.onToggle ) {
      listeners.onToggle(isExpanded)
    }
  }


  render() {
    return (
      <>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="" expanded={this.state.isExpanded} >
          <div className="container-lg" >

            <div className="nav-top-section nav-mobile-shadow">
              <Navbar.Toggle aria-controls="responsive-navbar-nav"  onClick={this.onToggle}   />
              <Navbar.Brand > 
                <Link to="/" onClick={this.closeFromClick} >
                  <img
                    src={process.env.PUBLIC_URL + "/img/hmk-logo-horizontal.svg"}
                    alt="Hallmark Video Greeting Cards"
                    height="65"
                  />
                </Link>
              </Navbar.Brand>
            </div>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
              {/* <ul className="navbar-expand-lg  navbar-nav"> */}
                <li className="nav-item ">
                  <Nav.Link href="/browse" onClick={this.closeFromClick} >
                    Create a Video Greeting
                    <span className="arrow">
                      <img
                        src={arrowright}
                        alt="arrowrighticon"
                        className="arrowright-icon"
                        />
                    </span>
                  </Nav.Link>
                </li>
                <li className="nav-item ">
                  <Nav.Link href="/list"  onClick={this.closeFromClick} >
                    My Video Greetings
                    <span className="arrow">
                      <img
                        src={arrowright}
                        alt="arrowrighticon"
                        className="arrowright-icon"
                      />
                    </span>
                  </Nav.Link>
                </li>
                
                { isLoggedIn() ? (
                  <li className="nav-item d-none d-lg-block"> 
                    <button className="nav-link black-link" onClick={logOut}>
                      <img
                        src={signouticon}
                        alt="signouticon"
                        className="signout-icon"
                      />
                      Sign out
                    </button>
                  </li>
                ):(
                  <li className="nav-item d-none d-lg-block">
                    <Link to="/login" className="nav-link black-link d-none d-lg-block" onClick={this.closeFromClick}  >
                      <img
                        src={signInIcon}
                        alt="signInIcon"
                        className="signin-icon"
                        />
                      Sign In
                    </Link>
                  </li>
                )}

                <li className="nav-item d-lg-none"> 
                  <Nav.Link  href="/faq" className="nav-link d-lg-none" onClick={this.closeFromClick}>
                    FAQ
                    <span className="arrow">
                      <img
                        src={arrowright}
                        alt="arrowrighticon"
                        className="arrowright-icon"
                      />
                    </span>
                  </Nav.Link >
                </li>
            
                <li className="nav-item d-lg-none"> 
                  <Nav.Link  href="/tutorials" className="nav-link d-lg-none" onClick={this.closeFromClick}>
                    Watch a Tutorial
                    <span className="arrow">
                      <img
                        src={arrowright}
                        alt="arrowrighticon"
                        className="arrowright-icon"
                      />
                    </span>
                  </Nav.Link >
                </li>
        {/* </ul> */}
        <div className="banner-image" />
          {isLoggedIn() ? (
            <div className="d-block d-lg-none mob_account1">
              <span className="navbar-text">
                <Link to="/change-password" onClick={this.closeFromClick} >
                  <img
                    src={settings}
                    alt="settingsicon"
                    className="settings-icon"
                  />
                  Change password
                </Link>
              </span>
              <br />
              <span className="navbar-text">
                <a href="tel:+18775808847" onClick={this.closeFromClick}>
                  <img
                    src={contactIcon}
                    alt="contactIcon"
                    className="settings-icon"
                  />
                  Contact us
                </a>
              </span>
              <br />
              <span className="navbar-text">
                <button className="link" onClick={logOut}>
                  <img
                    src={signouticon}
                    alt="signouticon"
                  />
                  Sign out
                </button>
              </span>
            </div>
          ):(
            <div className="d-block d-lg-none mob_account1">
              <span className="navbar-text">
                <a href="tel:+18775808847">
                  <img
                    src={contactIcon}
                    alt="contactIcon"
                    className="settings-icon"
                  />
                  Contact us
                </a>
              </span>
              <br />
              <span className="navbar-text">
                <Link to="/login"  onClick={this.closeFromClick} >
                  <img
                    src={signInIcon}
                    alt="signInIcon"
                    />
                  Sign in
                </Link>
              </span>
            </div>
          )}

          <Footer hideMobile={false} className="d-lg-none" />
    </Nav>
  </Navbar.Collapse>
  </div>
</Navbar>


      </>
    );
  }
}

export default NavBar;
